/**
 * @Author: zhangwei
 * @Date: 2019/12/22 11:16 AM
 * @desc:
 **/
import React, { Component } from 'react'
import { Menu, Icon, message, Dropdown, Badge, Typography, Tooltip, 
  Modal, Input,TreeSelect,Select
 } from 'antd'
import { Link, withRouter} from 'react-router-dom'
import { connect } from 'react-redux'
import Comp from '@/assets/images/comp.svg'

import {formatGMTTime} from '@/api/util'
import {listDepartment, createDepartment, setDepartmentUser} from '@/api/department'
import {searchDepartment} from '@/api/search'

const { SubMenu } = Menu;
const { Paragraph } = Typography;
const { Option } = Select;

const mapStateToProps = (state) => {
  return {
    userInfo: state.userInfo
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    getUserPlatforms : dispatch.userInfo.getUserPlatforms,
    updateMeInfo: dispatch.userInfo.updateMeInfo,
    updateUserInfo: dispatch.userInfo.updateUserInfo,
    getPlatformDepartments: dispatch.userInfo.getPlatformDepartments,
  }
}


@connect(mapStateToProps, mapDispatchToProps)
class PlatformMenus extends Component {
  constructor(props) {
    super(props)
    this.state = {
      current: this.props.userInfo.platform_id,
      show_dot:false,
      depts: [],

      show_dialog: false,
      save_loading:false,
      type: null,

      title:'',
      title_ok:'',
      title_error:'',

      docker: null,
      docker_select: null,
      docker_tree:[],
      
    }

    this.props.getUserPlatforms().then(res=>{
      if (res && res.length < 1) {
        this.setState({
          show_dot:true,
        })
      }

      res.items.map(it=>{
        if (it.id === this.props.userInfo.platform_id) {
          this.listPlatformDepartment(it.id);
        }
      })

    });
  }

  listPlatformDepartment=(id)=>{
    this.props.getPlatformDepartments(id).then(res=>{
      this.setState({
        depts: res.items,
      })

      if (typeof this.props.onLoadDepartment === "function") {
        this.props.onLoadDepartment(res);
      }

      if (typeof this.props.userInfo.dept_id !== "undefined" && this.props.userInfo.dept_id.length > 0) {
        res.items.map(it=>{
          if (it.id === this.props.userInfo.dept_id) {
            this.setState({current: 'dept#' + it.id});
          }
        })
      }
      
    })
  }

  componentDidMount () {
    // console.log("current user info: "+ JSON.stringify(this.props.userInfo));
  }

  handleClick = e => {
    var arr = e.key.split("#");
    if (arr[0] === "plat" && arr[1] === "new") {
      // this.props.subMenu({
      //   menu: "platform",
      //   sub_menu: 'new',
      // });
      this.props.history.push({pathname: "/platform/new"});
      return;
    }
    
    if (arr[0] === "dept" && arr[1] === "new") {
      this.setState({show_dialog:true})
      return;
    }

    console.log("menu clicked at: ", e.key, arr);
    var status = 'active';
    var id = '';
    if (arr.length > 1) {
      id = arr[1];
    }

    if (arr.length === 3) {
      status = arr[2];
    }


    // message.error("menu: " + e.key + " id: " + id + " status: " + status);
    if (arr[0] === "plat") {
      this.setState({
        current: e.key,
      });

      if (status && status !== 'active') {
        this.props.history.push({pathname: "/platform/license/" + id, query:{id: id, status: status}});
        return;
      }
      
      if (status && status === 'active') {
        this.props.updateMeInfo({platform: id}).then((res)=>{
          // this.setState({
          //   current: 'plat#' + res.platform_id + "#" + res.status,
          // });
        }).catch((err)=>{
          message.error();
        })
        return;
      }
      

      return;
    }

    // dept
    if (arr[0] === "dept") {
      if (arr[1] === this.props.userInfo.dept_id) {
        return;
      }

      const data = {
        userId: this.props.userInfo.id,
        deptId: arr[1],
      }

      //console.log("set user platform: ", data);

      setDepartmentUser(data).then(res=>{
        this.setState({
          current: e.key,
        });
        this.props.updateUserInfo({
          dept_id: data.deptId,
        })
      }).catch(e=>{
        message.error(e.message);
      })
    }

  }

  onTypeChange=(value)=>{
    this.setState({
			type: value,
			type_error:'',
		})
  }

  onTitleChange=(e)=>{
    this.setState({
      title:e.target.value,
      title_error:'',
    })
  }

  onDockerSelect=(value)=>{
    var docker = this.state.docker; 

    this.state.docker_tree.map(item=>{
      if (item.name === value) {
        docker = item;
      }
    })

    this.setState({
      docker: docker,
      docker_select: value,
      docker_error:'',
    })
  }

  onSearchDocker=(value)=>{
    searchDepartment({info: value}).then(res=>{
      if (typeof res.items !== "undefined" ) {
        res.items.map(item=>{
            item.title = item.name;
            item.value = item.name;
        })

        this.setState({docker_tree: res.items});
      }
    }).catch(err=>{
      message.error(err.message);
    })

    // searchDepartment
  }

  onSaveDialog=()=>{
    if (this.state.save_loading === true) {
      return false;
    }

    if (this.state.title === "" || this.state.title.length < 3 || this.state.title.length > 15) {
      this.setState({
        title_error:'请输入迭代主题的长度控制在[3, 15]',
      })

      return false;
    }

    if (!this.state.docker) {
      this.setState({
        docker_error:'部门长不可为空',
      })

      return false;
    }

    this.setState({
      save_loading:true,
    })

    const data={
      name: this.state.title,
      leader: this.state.docker.src_id,
      parent: this.state.type,
    }

    createDepartment(data).then(res=>{
      var list =[];
      list.push(res);
      this.state.depts.map(it=>{
        list.push(it);
      })

      this.setState({
        save_loading:false,
        show_dialog:false,
        depts:list,
      })
    }).catch(e=>{
      this.setState({
        save_loading:false,
      })
      message.error(e.message);
    })

    return true;
  }

  render() {
    //console.log("current user info: "+ JSON.stringify(this.props.userInfo));
    let defaultPlatformName='';
    var items = [];
    var focus = false;
    
    for(let index in this.props.userInfo.platforms.items) {
        let platform = this.props.userInfo.platforms.items[index];
        if (platform.status !== 'active') {
          focus = true;
        }
        if (platform.id === this.props.userInfo.platform_id) {
          defaultPlatformName = platform.name;
          // items.push(
          //   <Menu.Item key={platform.id}>
          //     <Badge  dot={platform.status !=='active' ? true : false} offset={[5, 6]} >
          //     <Icon type="pushpin" />
          //     <span className={'comp_title'} title={platform.name}>{platform.name}</span>
          //     </Badge>
          //   </Menu.Item>
          // );

          items.push(
            <SubMenu className={'sub-menu-active'} key={platform.id} title={
              <div className={'sub-menu-text'}>
                <Icon type="pushpin" />
                <span className={'comp_title'} title={platform.name}>{platform.name}</span>
              </div>
            } >
              {
                this.state.depts.map(it=>{
                  return (
                    <Menu.Item key={'dept#' + it.id}>
                      <Icon type="team" />
                      <span className={'comp_title'}>{it.name}</span>
                    </Menu.Item>
                  )
                })
              }
              <Menu.Item key='dept#new'>
                <Icon type="plus" style={{ color: 'rgba(36,150,255,0.95)',transform:'scale(1.2)' }}/>
                <span className={'comp_title'}>新建部门</span>
              </Menu.Item>
            </SubMenu>
          );
        }
        else
        {
          items.push(
            
            <Menu.Item key={"plat#"+platform.id + "#" + platform.status} >
              <Badge dot={platform.status !=='active' ? true : false} offset={[5, 6]} > 
              <Icon type="pushpin" />
              <span className={'comp_title'} title={platform.name}>{platform.name}</span>
              </Badge>
            </Menu.Item>
          );
        }
    };

    items.push(
        <Menu.Item key={"plat#new"}>
          <Icon type="plus" style={{ color: 'rgba(36,150,255,0.95)',transform:'scale(1.2)' }} />
          <span className={'comp_title'}>新建公司信息</span>
        </Menu.Item>
    );

    if (! this.props.userInfo.platform_id) {
      defaultPlatformName= '新建公司信息';
    }

    if (this.props.userInfo.platforms.length < 1 || this.props.userInfo.platforms.items.length < 1) {
      focus = true;
    }

    // console.log("platform name: " + defaultPlatformName)

    return (
      <div className={'avatar-area'}>
        <Dropdown overlay={
          <Menu
            className={'header-menu'}
            theme="light"
            mode="horizontal"
            defaultSelectedKeys={['homepage']}
            style={{ lineHeight: '64px' }}
            onClick={this.handleClick}
            selectedKeys={[this.state.current]}
          >
            {items}
          </Menu>
        }>
          
          <div className="submenu-title-wrapper">
            <Paragraph ellipsis={{ rows: 1, expandable: false }} className={'submenu-title-wrapper-title'}>
              <Badge offset={[0, 0]} dot={this.state.show_dot || focus}>
                <Icon type="team" className={"badge-icon"} />
              </Badge>
              <span>{defaultPlatformName}</span>
            </Paragraph>
          </div>
          
        </Dropdown>
        <Modal
          title={defaultPlatformName + " 新建部门"}
          className={'log-dialog'}
          centered
          closable={false}
          visible={this.state.show_dialog}
          confirmLoading={this.state.save_loading}
          onCancel={()=>{this.setState({show_dialog: false})}}
          onOk={this.onSaveDialog.bind(this)}
          cancelText="取消"
          okText="创建部门"
        >
            {
              <div className={'lgd-content'}>
                <table className={'lgd-post-content'}>
                  <tbody>
                    <tr>
                      <th className={'pc-title'}><span className={'must'}>*</span>部门名称</th>
                      <th>
                        <Input
                          className={'pc-in'}
                          placeholder={'请输入部门名称'}
                          allowClear={true}
                          value={ this.state.title }
                          onChange={this.onTitleChange}
                        />
                        <div className={this.state.title_error ? "ptl-error" : "ptl-error none"}>
                          <Icon type="info-circle" />
                          <span>{this.state.title_error}</span>
                        </div>
                        <div className={this.state.title_ok ? "ptl-ok" : 'ptl-ok none'}>
                          <Icon type="check" />
                        </div> 
                      </th>
                    </tr>
                    <tr>
                      <th className={'pc-title'}><span className={'must'}>*</span>部  门  长</th>
                      <th>
                          <TreeSelect
                            className={'tree-select'}
                            showSearch
                            allowClear
                            value={this.state.docker_select}
                            treeData={this.state.docker_tree}
                            placeholder={'通过人名搜索'}
                            dropdownStyle={{ maxHeight: 400, overflow: 'auto' }}
                            treeDefaultExpandAll
                            onChange={this.onDockerSelect}
                            onSearch={this.onSearchDocker}
                            />
                          <div className={this.state.docker_error ? "ptl-error" : "ptl-error none"}>
                            <Icon type="info-circle" />
                            <span>{this.state.docker_error}</span>
                          </div>
                          <div className={this.state.docker_ok ? "ptl-ok" : 'ptl-ok none'}>
                            <Icon type="check" />
                          </div> 
                        </th>
                    </tr>
                    <tr>
                      <th className={'pc-title'}>上级部门</th>
                      <th>
                        <Select 
                          className={'ant-len'}
                          defaultValue={this.state.type}  onChange={this.onTypeChange}
                          allowClear
                          placeholder="选择上级部门，可以为空"
                        >
                          {
                            this.state.depts.map(item=>{
                              return (<Option value={item.id}>{item.name}</Option>)
                            })
                          }
                        </Select>
                        
                        <div className={this.state.type_error ? "ptl-error" : "ptl-error none"}>
                          <Icon type="info-circle" />
                          <span>{this.state.type_error}</span>
                        </div>
                        <div className={this.state.type_ok ? "ptl-ok" : 'ptl-ok none'}>
                          <Icon type="check" />
                        </div> 
                      </th>
                    </tr>
                  </tbody>
                </table>
              </div>
            }
        </Modal>
      </div>
    );

  }
}

export default withRouter(PlatformMenus);
