/**
 * @Author: hanjianjian
 * @Date: 2019/12/22 11:16 AM
 * @desc:
 **/
import React, { Component } from 'react'
import { Layout, Avatar, Icon, Menu, Dropdown, Badge, message } from 'antd'
import { Link, withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import Comp from '@/assets/images/comp.svg'
import { isEmpty } from '@/api/util'
import {getEventCount} from '@/api/event'

const { SubMenu } = Menu;

const mapStateToProps = (state) => {
  return {
    userInfo: state.userInfo
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    getUserPlatforms : dispatch.userInfo.getUserPlatforms,
	logout: dispatch.userInfo.logout,
	updateUserInfo: dispatch.userInfo.updateUserInfo,
  }
}


@connect(mapStateToProps, mapDispatchToProps)
class UserMenus extends Component {
  constructor(props) {
    super(props)
    this.state = {
      current: '',
    }
    
  }

  componentDidMount () {
	this.timer && clearInterval(this.timer);
	this.timer=setInterval(
		() => { this.onTimerGetUserInfo(); },
		20000
	);
  }

  componentWillUnmount() {
	this.timer && clearInterval(this.timer);
  }

  onTimerGetUserInfo=()=>{
	getEventCount().then(res=>{
		var update = false;
		if (!isEmpty(res.event_count) && this.props.userInfo.event_count !== res.event_count) {
			update = true;
		}

		if (!isEmpty(res.audit_count) && this.props.userInfo.audit_count !== res.audit_count) {
			update = true;
		}

		if (update === true) {
			this.props.updateUserInfo({
				event_count: res.event_count,
				audit_count: res.audit_count,
			})
		}

	}).catch(e=>{

	})
  }

  handleClick = e => {
    this.setState({
      current: e.key,
    });

    if (e.key === 'logout') {
    	this.props.logout().then(()=>{
    		 this.props.history.push("/");
    	});
    }
    
    this.props.subMenu({
        menu: "user",
        sub_menu: e.key,
    });

    // message.error('menu clicked at: ' + e.key);
  };

  render() {
    //console.log("current user info: "+ JSON.stringify(this.props.userInfo));
    var items = [];
	// console.log("current user avatar_url: " + this.props.userInfo.avatar_url);
	
	var dot = false;
	if (this.props.userInfo.is_verified === false) {
		dot = true;
	}

	if (this.props.userInfo.event_count > 0) {
		dot = true;
	}

	if (this.props.userInfo.audit_count > 0) {
		dot = true;
	}

    return (
		<div className={'avatar-area'}>
		  <Dropdown overlay={
		  	<Menu
        	defaultSelectedKeys={['homepage']}
        	style={{ lineHeight: '64px' }}
        	onClick={this.handleClick}
        	selectedKeys={[this.state.current]}
		  	>
		  		{
		  			this.props.userInfo && !isEmpty(this.props.userInfo.email) ?
		  			<Menu.Item key="email" disabled={true}>
				      <Link to="#" className={'menu-link'} >
				      	<Icon type="mail" className={'ant-icon'} />
				      	<span className={'menu-text'}>{this.props.userInfo.email}</span>
				      </Link>
				    </Menu.Item>
				    : ''
		  		}

				{
			    	!isEmpty(this.props.userInfo.access_token) ?
			    	<Menu.Item key="user-join">
				      <Link to="#" className={'menu-link'} >
				      	<Icon type="usergroup-add" className={'ant-icon'} /> 
				      	<span className={'menu-text'}>面对面加群</span>
				      </Link>
				    </Menu.Item>
				    : ''
			    }

				<Menu.Divider/>
			    <Menu.Item key="idcard">
			      <Link to="/setting/verify" className={'menu-link'} >
			      <Badge offset={[20, 8]} dot={!this.props.userInfo.is_verified} >
			      	<Icon type="idcard" className={'ant-icon'} />
			      	<span className={'menu-text'}>实名认证</span>
			      </Badge>
			      </Link>
			    </Menu.Item>
			    <Menu.Item key="setting">
			      <Link to="/setting/user" className={'menu-link'} >
			      	<Icon type="setting" className={'ant-icon'} />
			      	<span className={'menu-text'}>设置</span>
			      </Link>
			    </Menu.Item>

			    <Menu.Divider/>
		  		<Menu.Item key="message">
			      <Link to="/message/list" className={'menu-link'} >
			      	<Badge offset={[30, 7]} count={this.props.userInfo.event_count} >
				      	<Icon type="message" className={'ant-icon'} />
				      	<span className={'menu-text'}>消息</span>
			      	</Badge>
			      </Link>
			    </Menu.Item>
				{
					!isEmpty(this.props.userInfo.is_admin) && this.props.userInfo.is_admin === true ?
					<Menu.Item key="audit">
					<Link to="/audit/list" className={'menu-link'} >
						<Badge offset={[30, 7]} count={this.props.userInfo.audit_count} >
							<Icon type="audit" className={'ant-icon'} />
							<span className={'menu-text'}>审核</span>
						</Badge>
					</Link>
					</Menu.Item>
					: ''
				}
			   
			    <Menu.Divider/>
			    <Menu.Item key="logout">
			    	<Badge dot={false} offset={[10, 7]} >
			      	<Icon type="logout" /> 退出登录
			      	</Badge>
			    </Menu.Item>
			</Menu>
		  }>
		    <div>
		      <Badge offset={[0, 5]} dot={dot}>
		      <Avatar
		        className={'user-avatar'}
		        icon={<Icon type="user" />}
		        src={this.props.userInfo.avatar_url !==''? this.props.userInfo.avatar_url : ''}
		        alt={this.props.userInfo.nick_name}
		         />
		        </Badge>
		      <span className={'user-name'}>{ this.props.userInfo.nick_name }</span>
		    </div>
		  </Dropdown>
		</div>
    )
  }
}

export default withRouter(UserMenus)


