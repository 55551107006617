import { baseUri, httpPost, httpGet, httpPut, httpDelete} from './fetch'



export const searchDepartment = (data) =>{
	return httpGet({
		url: baseUri('/search/dept'),
		data
	})
}


export const searchDb = (data) =>{
	return httpGet({
		url: baseUri('/search2'),
		data
	})
}

export const search = (data) =>{
	return httpGet({
		url: baseUri('/search'),
		data
	})
}
