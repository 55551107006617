
import React, { Component } from 'react'
import  styles from './register.scss';
import { Layout, Button, Input, Icon, Row, Col, message } from 'antd'
import { Link, withRouter} from 'react-router-dom'
import { connect } from 'react-redux'

import Checkbase from '@/assets/images/check_base.svg'
import Checkok from '@/assets/images/check-ok.svg'
import Metux from '@/assets/images/metux.svg'
import {createUser} from '@/api/user'

/**
 * 子组件内容
 * @param title 标题
 * @param children 内容
 * @param restProps 传入的自定义属性
 * @returns {*}
 * @constructor 眼睛

 const LgPanel = ({children,...restProps})=>{
 */

const InputGroup = Input.Group
const mapStateToProps = (state) => {
  return {
    userInfo: state.userInfo
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    getConfirmCodeFoo: dispatch.userInfo.getConfirmCodeFoo,
    getLoginFoo: dispatch.userInfo.getLoginFoo,
  }
}

@connect(mapStateToProps, mapDispatchToProps)
class LgRegister extends Component{
    constructor(props) {
        super(props);
        this.state = {
            protocol_checked:true,
            register:false,
            confirm:false,
            confirm_count:0,
            login_type:0,
            login_btn:false,
            login_loading:false,
            password:'',
        };        

        // console.log("time zoo: " + this.getClientTimezone());
        // console.log("web run at os: " + this.getWebOs());

    }

    checkProtocol =()=>{
        this.setState({
            protocol_checked:!this.state.protocol_checked,
        })
    }

    componentDidMount () {
      this.input.focus();
    }

    componentWillReceiveProps(nextProps){
      this.setState({
            register: nextProps.register === false ? false : true,
          }, function(){
            this.input.focus();
          });

    }

    getClientTimezone(){  
      var oDate = new Date();
      
      var nTimezone = -oDate.getTimezoneOffset() / 60;  
      return nTimezone.toFixed(2);  
    }

    getWebOs(){
      var name = "unknown";
      var ua = window.navigator.userAgent;
      //console.log("web ua: " + ua);
      if(ua.indexOf("Android") != -1){
            name = "Android";
        }else if(ua.indexOf("iPhone") != -1){
            name = "iPhone";
        }else if(ua.indexOf("SymbianOS") != -1){
            name = "SymbianOS";
        }else if(ua.indexOf("Windows Phone") != -1){
            name = "Windows Phone";
        }else if(ua.indexOf("iPad") != -1){
            name = "iPad";
        }else if(ua.indexOf("iPod") != -1){
            name = "iPod";
        }
        else if (ua.indexOf("Windows NT 10.0")!= -1){
            name="Windows 10";
        }else if (ua.indexOf("Windows NT 6.2") != -1){
            name="Windows 8";
        }else if (ua.indexOf("Windows NT 6.1") != -1){
            name="Windows 7";
        }else if (ua.indexOf("Windows NT 6.0") != -1){
            name="Windows Vista";
        }else if (ua.indexOf("Windows NT 5.1") != -1){
            name="Windows XP";
        }else if (ua.indexOf("Windows NT 5.0") != -1){
            name="Windows 2000";
        }else if (ua.indexOf("Mac") != -1){
            name="Mac/iOS";
        }else if (ua.indexOf("Ubuntu") != -1){
            name="Ubuntu";
        }else if (ua.indexOf("X11") != -1){
            name="UNIX";
        }else if (ua.indexOf("Linux") != -1){
            name="Linux";
        }

        return name;
    }

    /**
    *  e => this.setState({ phone: e.target.value })
    */
    updateAuthInput =(e)=>{
      const telStr = /^[1]([3-9])[0-9]{9}$/

      //console.log("user input: "+ e.target.value);
      this.setState({
        phone: e.target.value
      })
    }


    onLoginType =(name)=>{
      console.log("login with: " + name);
    }

    /**
   * @author victorzhang
   * @Description 获取验证码
   * @Date 10:24 上午 2019/12/24
   * */
  getConfirmCode() {
    const telStr = /^[1]([3-9])[0-9]{9}$/;
    const emailStr = /^\w+((-\w+)|(\.\w+))*\@[A-Za-z0-9]+((\.|-)[A-Za-z0-9]+)*\.[A-Za-z0-9]+$/;
    if (this.state.phone === null || this.state.phone === '') {
      message.error('用户名/邮箱/手机号不能为空')
      return false
    }
    
    if (!(telStr.test(this.state.phone)) && !(emailStr.test(this.state.phone))) {
      message.error('请输入正确的邮箱或手机号')
      return false
    }

    if (this.state.confirm) {
      return false;
    }

    this.setState({
      confirm:true, 
      confirm_count:60,
      login_type:1,
    });

    this.confirmTimer = setInterval(() => this.updateConfirmCount(), 1000);
    
    const data = {
      area: this.state.country_code,
      auth: this.state.phone,
      type: this.props.register ? 'register':'login',
      state:'',
    }
    this.props.getConfirmCodeFoo(data)
  }

  updateConfirmCount =()=>{
    this.setState({
      confirm_count: this.state.confirm_count - 1,
    }, function(){
      if (this.state.confirm_count <= 0) {
        this.setState({
          confirm:false,
        });

        clearInterval(this.confirmTimer);
      }
    });
  }

  onVerifyCodeChange=(register, e)=>{
    //console.log("on verify code change: ", e.target.value, " register: ", register)
    const strNumber = /^[0-9]{0,6}$/
    if (register === true) {
      if (!strNumber.test(e.target.value)) {
        return;
      }
    }
    this.setState({
      mobile_code: e.target.value,
    })
  }

  onPasswordChange=(e)=>{
    this.setState({
      password:e.target.value,
    })
  }

  /**
   * @author victorzhang
   * @Description 登录
   * @Date 14:11 上午 2019/12/24
   * */
  toLogin() 
  {
    if (this.state.login_btn) {
      return false;
    }

    const telStr = /^[1]([3-9])[0-9]{9}$/
    if (this.state.phone === null || this.state.phone === '') {
      message.error('用户名/邮箱/手机号不能为空')
      return false
    }
 
    if (this.state.mobile_code === null || this.state.mobile_code === '') {
      message.error('密码/验证码格式不对')
      return false
    }

    //console.log("props.register: ", this.props);

    if (this.props.register === true && (this.state.password === null || this.state.password.length < 6 || this.state.password.length > 20)) {
      message.error('密码异常，长度控制在[6,20)间');
      return false;
    }

    var strPwd = new RegExp('(?=.*[0-9])(?=.*[a-zA-Z]).{6,20}');
    if (this.props.register === true && !strPwd.test(this.state.password)) {
      message.error('您的密码复杂度太低（密码中必须包含字母、数字），请及时改密码！');
      return false;
    }

    if (this.state.protocol_checked === false) {
      message.error("需要同意小鹿工厂的用户协议才能登陆");
      return false;
    }

    var data = {
      userName: this.state.phone, 
      password: this.state.mobile_code,
      os: this.getWebOs(),
      osType:'web',
    }

    if (this.props.register === true) {
      data = {
        auth: this.state.phone,
        code: this.state.mobile_code,
        password: this.state.password,
        os: this.getWebOs(),
        osType: 'web',
      }
    }

    this.setState({
      login_btn:true,
      login_loading:true,
    })

    const func = this.props.register === true ? createUser : this.props.getLoginFoo;

    func(data).then(() => {
      //this.props.history.push('/');
      this.setState({
        login_btn:false,
        login_loading:false,
      });
      //window.location.reload(false);
      var index = window.location.href.indexOf(window.location.host);
      const path = window.location.href.substr(index + window.location.host.length);
      this.props.history.push(path);
      this.props.onSuccess();

    }).catch((err) => {
      //console.log("err: ", err);
     
      this.setState({
        login_btn:false,
        login_loading:false,
      })
      
      if (typeof err.code !== 'undefined' && err.code === 'ECONNABORTED') {
        message.error('小鹿工厂服务器不在线，请稍后重试~~');     
      }
      else
      {
        message.error(err.message);
      }

    })
  }

  createMoreLogin=(register)=>{
    return (<div className={'more-login'} />);

    return (
      <div className={'more-login'}>
        <div className={'sep-main'}>
          <div className={'sep'} />
          <div className={'sep-content'}>更多登录方式</div>
          <div className={'sep'} />
        </div>
        <div className={'input-line more-logins'}>
            <div className="login-type" onClick={this.onLoginType.bind(this, 'metux')}>
                <img src={Metux} style={{width:'30px', verticalAlign:'middle', marginTop:'-5px'}} />
            </div>
            <div className="login-type" onClick={this.onLoginType.bind(this, 'qq')}>
                <Icon type="qq" style={{ color: 'rgba(36,150,255,0.95)',transform:'scale(2.1)' }} />
            </div>
            <div className="login-type" onClick={this.onLoginType.bind(this, 'wechat')}>
                <Icon type="wechat" style={{ color: 'rgba(36,150,255,0.95)',transform:'scale(2.1)' }} />
            </div>
            <div className="login-type" onClick={this.onLoginType.bind(this, 'weibo')}>
                <Icon type="weibo" style={{ color: 'rgba(36,150,255,0.95)',transform:'scale(2.1)' }} />
            </div>
        </div>
      </div>
    );
  }



	render() {
        const {
            children,
            register,
            ...restProps
        } = this.props;

        // console.log("register: " + register);
        return (
	      <div className={'register-body'}>
              <div className={'login-style-main'}>
                <div className={'login-index'}>
                  <div className={'ant-form'}>
                    <div className={'login-tabs'}>
                      <div className={'login-way'}>{register===true ? '免费注册':'登录'}小鹿工厂</div>
                    </div>
                    <div className={'input-area'}>
                      <div className={'input-line'}>
                        <Input
                          placeholder={register ? "邮箱 / 手机号": "用户名 / 邮箱 / 手机号"}
                          prefix={<Icon type="mobile" style={{ color: 'rgba(0,0,0,.25)' }} theme={'twoTone'} />}
                          allowClear={true}
                          value={ this.state.phone }
                          ref={(input) => this.input = input}
                          onChange={this.updateAuthInput}
                          onPressEnter={this.toLogin.bind(this)}
                          disabled={this.state.login_loading}
                        />
                      </div>
                      <div className={'input-line'}>
                        <InputGroup size="large">
                          <Row gutter={16}>
                            <Col span={16}>
                              <Input
                                placeholder={register ? "验证码": "密码 / 验证码"}
                                prefix={<Icon type={register ? "number": 'lock'} style={{color: 'rgba(36,150,255,.85)'}} />}
                                type={register ? 'text' : 'password'}
                                allowClear={true}
                                value={ this.state.mobile_code }
                                onChange={this.onVerifyCodeChange.bind(this, register)}
                                onPressEnter={this.toLogin.bind(this)}
                                disabled={this.state.login_loading}
                              />
                            </Col>
                            <Col span={8}>
                              <Button
                                onClick={this.getConfirmCode.bind(this)}
                                disabled={this.state.login_loading}
                              >
                                {this.state.confirm ? '获取中('+this.state.confirm_count+')': '获取验证码'}
                              </Button>
                            </Col>
                          </Row>
                        </InputGroup>
                      </div>
                      {
                        register ? 
                        <div className={'input-line'}>
                          <Input.Password 
                            placeholder={"设置登录密码"}
                            prefix={<Icon type="lock" style={{ color: 'rgba(0,0,0,.25)' }} theme={'twoTone'} />}
                            allowClear={true}
                            value={ this.state.password }
                            onChange={this.onPasswordChange}
                            onPressEnter={this.toLogin.bind(this)}
                            disabled={this.state.login_loading}
                          />
                        </div>
                        : ''
                      }
                    </div>
                    <div className={'input-line user-protocol'} onClick={this.checkProtocol}>
                        <img src={this.state.protocol_checked ? Checkok : Checkbase}/>
                        <span>{register ? '注册': '登录'}小鹿工厂即同意<a href="/about/protocol" target="_blank">《小鹿工厂的用户协议》</a> </span>
                    </div>

                    <div className={'login-btn-row'}>
                      <Button type="primary"
                              className={'login-btn'}
                              loading={this.state.login_loading}
                              onClick={this.toLogin.bind(this)}
                      >{register ? '免费注册' : '登录'}</Button>
                    </div>
                    {this.createMoreLogin(register)}
                  </div>
                </div>
              </div>
		  </div>
  		)
    }
}

export default withRouter(LgRegister);





