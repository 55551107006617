import { baseUri, httpPost, httpGet, httpPut, httpDelete} from './fetch'


export const listEvent = (data) =>{
	return httpGet({
		url: baseUri('/event/list'),
		data
	})
}

export const getEventCount = (data) =>{
	return httpGet({
		url: baseUri('/event/count'),
		data
	})
}