import { baseUri, httpPost, httpGet, httpPut } from './fetch'



export const getAllLogInfoApi = (data) =>{
	return httpPost({
		url: baseUri('/bind/list'),
		data
	})
}


export const userLogoutApi = (data) =>{
	return httpPost({
		url: baseUri('/auth/logout'),
		data
	})
}

/**
*
* 用户登录 
*
request{
	userName:
	password:
	os:
	osType:
	deviceName:
}
response:{
	{device}
}
*
**/
export const userAuthApi = (data) =>{
	return httpPost({
		url: baseUri('/auth'),
		data
	})
}


export const createUser = (data) =>{
	return httpPost({
		url: baseUri('/user'),
		data
	})
}


/**
*
* 获取其他用户信息 
*
request:{
	id:
}
response:{
	{user}
}
*
**/
export const userInfoApi = (data) =>{
	return httpGet({
		url: baseUri('/user'),
		data
	})
}

/**
*
* 获取其他用户信息 
*
request:{
	id:
}
response:{
	{user}
}
*
**/
export const getUserSimpleInfoApi = (data) =>{
	return httpGet({
		url: baseUri('/user/simple'),
		data
	})
}


/**
*
* 获取当前用户信息 
*
request:null
response:{
	{user}
}
*
**/
export const userMeApi = (data) =>{
	return httpGet({
		url: baseUri('/user/me'),
		data
	})
}

/**
*
* 创建用户头像 
*
request:null
response:{
	{user}
}
*
**/
export const userMeAvatarApi = (data) =>{
	return httpPost({
		url: baseUri('/user/me/avatar'),
		data
	})
}

/**
*
* 创建用户头像 
*
request:null
response:{
	{user}
}
*
**/
export const userMeUpdateApi = (data) =>{
	return httpPut({
		url: baseUri('/user/me'),
		data
	})
}



/**
*
* 创建用户头像 
*
request:{
	id:
}
response:{
	{user}
}
*
**/
export const setUserMePlatformApi = (data) =>{
	return httpPut({
		url: baseUri('user/me/platform'),
		data
	})
}


export const getUserIdentity = (data) =>{
	return httpGet({
		url: baseUri('/identity'),
		data
	})
}

export const createUserIdentity = (data) =>{
	return httpPost({
		url: baseUri('/identity'),
		data
	})
}

export const updateUserIdentity = (data) =>{
	return httpPut({
		url: baseUri('/identity'),
		data
	})
}
