
import React, { Component } from 'react'
import  styles from './register.scss';
import { Layout, Button, Input, Icon, Row, Col, message, Tooltip, InputNumber } from 'antd'
import { Link, withRouter} from 'react-router-dom'
import { connect } from 'react-redux'


/**
 * 子组件内容
 * @param title 标题
 * @param children 内容
 * @param restProps 传入的自定义属性
 * @returns {*}
 * @constructor 眼睛

 const LgPanel = ({children,...restProps})=>{
 */

const InputGroup = Input.Group
const mapStateToProps = (state) => {
  return {
    userInfo: state.userInfo
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    getConfirmCodeFoo: dispatch.userInfo.getConfirmCodeFoo,
    getLoginFoo: dispatch.userInfo.getLoginFoo,
  }
}

@connect(mapStateToProps, mapDispatchToProps)
class LgPlatformCreate extends Component{
  constructor(props) {
      super(props);
      this.state = {
          code_focus : false,
      };
  }


  componentDidMount () {
    
  }

  componentWillReceiveProps(nextProps){
    this.setState({
          
        }, function(){
          
        });

  }

  joinOnePlatform =()=>{
    this.setState({
      code_loading:true
    })
  }

  onFocusCode =(e)=>{
    this.setState({
      code_focus:true,
    })
  }

  onBlurCode =(e)=>{
    this.setState({
      code_focus:false,
      code_loading:false,
    })
  }

  onInputCode =(e)=>{
    //console.log("input value: " + JSON.stringify(e.target.value));

    const strNumber = /^[0-9]*$/;
    var code = e.target.value.replace(" ","");
    if (!strNumber.test(code)) {
      return;
    }

    code = e.target.value;
    code += "";

    this.setState({
      code: code,
    });

    if (code.length > 3) {
      this.joinOnePlatform();
    };
  }


	render() {
        const {
            children,
            ...restProps
        } = this.props;
        return (
	      <div className={'register-body'}>
          <div className={'panel-title'}>
            <h1>公司信息</h1>
            <div className={'panel-title-info'}>新建或加入公司组织，开始体验</div>
          </div>
          <div className={'panel-platform-choose'}>
            <Link to="/platform/new-platform" className={'ppc-one'}>
              <Icon type="plus" style={{ color: 'rgba(36,150,255,0.95)',transform:'scale(1.2)'  }} />
              <span>新建公司信息</span>
            </Link>
            <Tooltip title="">
            <Input className={this.state.code_focus === true ? 'ppc-one active' : 'ppc-one'}
                maxLength={4}
                disabled={this.state.code_loading}
                placeholder={"输入4位口令"}
                prefix={<Icon type="number" style={{ color: 'rgba(36,150,255,0.95)',transform:'scale(1.2)' }} spin={this.state.code_loading} />}
                value={ this.state.code }
                onChange={this.onInputCode}
                onFocus={this.onFocusCode}
                onBlur={this.onBlurCode}
                onPressEnter={this.joinOnePlatform.bind(this)}
                  />
            </Tooltip>
          </div>
		  </div>
  		)
    }
}

export default withRouter(LgPlatformCreate);





