import React, { Component } from 'react'
import { Layout, Button, message, Menu, Icon , Input, ConfigProvider, Popover} from 'antd'
import { Link, withRouter} from 'react-router-dom'
import { connect } from 'react-redux'
import './TableEx.scss'
import { Table, Tag } from 'antd';

import {toJavaParam, isArray, isEmpty, removeList, addList}  from '@/api/util'



const { Search } = Input;

const mapStateToProps = (state) => {
  return {
    userInfo: state.userInfo
  }
}

const mapDispatchToProps = () => ({})

@connect(mapStateToProps, mapDispatchToProps)
class TableEx extends Component {
  constructor(props) {
    super(props);

    this.state = {
      list_loading:true,
      show_total:false,
      page_current: 1,
      columns: this.setColumEditable(this.props.columns),
      list: null,
      pagination: this.createPage(),
      lastIndex: null,
      edit:null,
      menu_record: null,
      sorter:{field: null, order: null},
      selectRows:[],
      showSelect: typeof this.props.showSelect !== "undefined" ? this.props.showSelect : false,
    }

    //console.log("table ex props: ", this.props);
  }

  componentDidMount () {
    window.onclick=this.onWindowClick.bind(this);
    this.loadItems();
    this.hideLastPage();
  }

  onWindowClick=(e)=>{
    //var mousePos = this.mousePosition(e);
    //console.log("current click event: ", e, e.target, e.target.parentElement, mousePos);
    var menus = document.querySelectorAll('.pop-menu');
    if (menus) {
      for (let index = 0; index < menus.length; index++) {
        const element = menus[index];
        element.style.display="none";
      }

      this.setState({
        menu_record: null,
      })
    }
  }

  componentWillReceiveProps(nextProps){
    if (typeof nextProps.reload === "boolean" && nextProps.reload === true) {
      this.setState({
        list:[],
        page_current: 1,
        lastIndex: null,
        list_loading:true,
        // pagination: this.createPage(),
      }, ()=>{
        this.loadItems();
        this.hideLastPage();
      })
    }

    if (typeof nextProps.updatedRecord !== "undefined" && nextProps.updatedRecord != null) {
      //console.log("add table list value: ", nextProps.updatedRecord);
      this.updateList(nextProps.updatedRecord);
      return;
    }

    if (typeof nextProps.list_loading === "boolean" && nextProps.list_loading !== null) {
      this.setState({
        list_loading: nextProps.list_loading,
      })
    }
  }

  componentWillUnmount() {
    
  }

  updateList=(records)=>{
    if (isEmpty(records)) {
      return;
    }

    if (typeof records === "function") {
      var list = []; 
      this.state.list.map(it=>{
        var ret = records(it);
        if (!isEmpty(ret)) {
          if (typeof ret.is_deleted === "boolean" && ret.is_deleted === true) {
          }
          else
          {
            if (isArray(ret)) {
              ret.map(it2=>{
                list.push(it2);
              })
            }
            else
            {
              list.push(ret);
            }
          }
        }
        else
        {
          list.push(it);
        }
      })

      this.setState({
        list: list,
      })

      return;
    }

    if (!isArray(records)) {
      var list=[];
      list.push(records);
      records = list;
    }

    var list = this.state.list;
    records.map(it=>{
      if (typeof it.is_deleted === "boolean" && it.is_deleted === true) {
        
        list = removeList(list, it);
      }
      else {
        list = addList(list, it);
      }
    })

    this.setState({
      list: list,
    })
  }

  isPromise=(obj)=> {
    if (obj == null) {
      return false;
    }

    return !!obj  //有实际含义的变量才执行方法，变量null，undefined和''空串都为false
      && (typeof obj === 'object' || typeof obj === 'function') // 初始promise 或 promise.then返回的
      && typeof obj.then === 'function';
  }

  onTextDoubleClick=(dataIndex, text, record, rowIndex, e)=>{
    if (this.state.edit) {
      this.onEditFinish(dataIndex, text, record, rowIndex, e);
    }

    //message.success("on text: " + text + " is clicked...");
    this.setState({
      edit:{column: dataIndex, row: rowIndex, text:''},
    }, function(){
      this.timer=setInterval(
        () => {
          this.onTimerToFocusEdit();
        },
        200
      );
    })
  }

  onTimerToFocusEdit=()=>{
    if (this.input) {
      this.input.focus();

      this.timer && clearInterval(this.timer);
    }
  }

  onEditFinish=(dataIndex, text, record, rowIndex, e)=>{
    this.timerToUpdateEditValue && clearInterval(this.timerToUpdateEditValue);
    this.timerToUpdateEditValue=setInterval(
      () => {
        this.onTimerToUpdateEditValue(dataIndex, text, record, rowIndex);
      },
      300
    );
  }

  onTimerToUpdateEditValue=(dataIndex, text, record, rowIndex)=>{
    //message.success("finish edit with column: " + dataIndex + " will upload ");
    //var realRow = (this.state.page_current - 1) * this.state.pagination.pageSize + rowIndex;
    var realRow = rowIndex;
    if (text !== this.state.edit.text && this.state.edit.text.length > 0) {
      //console.log("edit will upload column: " + dataIndex + " value: " + this.state.editText + " at index: " + rowIndex);
      var editText = this.state.edit.text;
      var list = this.state.list;
      list[realRow][dataIndex] = this.state.edit.text;
      //console.log("index: ", rowIndex, " updated list: ", list);

      if (typeof this.props.saveEdit === "function") {
          this.setState({
            list_loading:true,
            edit:null,
          })

          var ret = this.props.saveEdit(record, toJavaParam(dataIndex), editText);
          if (this.isPromise(ret)) {
            ret.then(res=>{
              this.setState({
                list_loading:false,
                edit:null,
                list:list,
              })
            }).catch(err=>{

              this.setState({
                list_loading:false,
              })

            })
          }
          else
          {
            this.setState({
                list_loading:false,
                edit:null,
                list:list,
              })
          }
      }
      else
      {
        this.setState({
          list_loading:false,
          edit:null,
          list:list,
        })
      }
      this.timerToUpdateEditValue && clearInterval(this.timerToUpdateEditValue);
      return;
    }

    this.setState({
      edit:null,
    })

    this.timerToUpdateEditValue && clearInterval(this.timerToUpdateEditValue);
  }

  onEditChange=(e)=>{
    if (e.target) {
      //console.log("edit value: ", e.target.value);
      this.setState({
        edit: {
          ...this.state.edit,
          text:e.target.value,
        },
      })
    }
  }

  setColumEditable=(columns)=>{
    var ret=[];
    columns.map(item=>{

      if (typeof item.show === 'boolean' && item.show === false) {
        return;
      }

      if (typeof item.show === 'function') {
        var retShow = item.show();
        if (typeof retShow === 'boolean' && retShow === false) {
          return;
        }
      }

      if (typeof item.edit === 'undefined' && typeof item.render === "function") {
        ret.push(item);
        return;
      }

      if (typeof item.edit === 'undefined' || item.edit === false) {
        //item.render={(text)=> {<span className={'editnot'}>{text}</span>},}
        var one={
          ...item,
          render:(text, record, index)=>{
            var showText = text;
            if (typeof item.extText === "function") {
              showText = item.extText(text, record, index);
            }
            return (<span className={'editnot'}>{showText}</span>);
          },
        }
        ret.push(one);
        return;
      }

      var one={
        ...item,
        render: (text, record, index)=>{
          var showText = text;
          if (typeof item.extText === "function") {
            showText = item.extText(text, record, index);
          }

          if (this.state.edit && this.state.edit.column === item.dataIndex && this.state.edit.row === index) {
            return (<Input 
              id={'id_edit-input_' + item.dataIndex + "_" + index}
              className={'edit-input'}
              allowClear={true}
              ref={(input) => this.input = input}
              prefix={<Icon type="edit" /> } 
              placeholder={showText} 
              onChange={this.onEditChange.bind(this)}
              onBlur={this.onEditFinish.bind(this, item.dataIndex, text, record, index)} 
              onPressEnter={this.onEditFinish.bind(this, item.dataIndex, text, record, index)}
              />);
          }
          return (<span className={'editable'} 
            onDoubleClick={this.onTextDoubleClick.bind(this, item.dataIndex, text, record, index)}
            >{showText}</span>)
        },
      }

      ret.push(one);
    })

    return ret;
  }

  createPage=()=>{
    var pagination={
      pageSize:10,
      hideOnSinglePage:true,
      total:1000000,
      showSizeChanger: false, // 显示可改变每页数量
      pageSizeOptions: ['10', '20', '50', '100'], // 每页数量选项
    }

    return pagination;
  }

  hideLastPage=()=>{
    var lstPageItems = document.getElementsByClassName("ant-pagination-item");
    if (lstPageItems === null || lstPageItems.length < 1) {
      return;
    }

    //console.log("hide page last page: ", this.state)
    lstPageItems[lstPageItems.length - 1].className += " hide";

    if (this.state.has_more !== false) {
      return;
    }

    for (var i = lstPageItems.length - 1; i >= 0; i--) {
      var nodes = lstPageItems[i].getElementsByTagName("a");
      if (nodes !== null && nodes.length > 0) {
        var num = parseInt(nodes[0].innerHTML);
        if (num > this.state.pos) {
          lstPageItems[i].className += " hide";
          //console.log("hide page: " + num);
        }
      }
      
    }
  }


  handleTablePageChange = (pagination, filters, sorter) =>{
    // console.log("table page: ", pagination, " filters: ", filters, " sorter: ", sorter);
    this.setState({
      list_loading:true,
      page_current: pagination.current,
      sorter:{
        field: typeof sorter.field !== "undefined" ? sorter.field : null,
        order: typeof sorter.order !== "undefined" ? sorter.order : null,
      },
    }, function(){
      this.loadItems();
    })
  }

  showTotal=(total)=>{
    return(<span className={'lg-table-total'}>共{total}条</span>)
  }

  loadItems=()=>{
    //console.log("table ex will load items: ", this.props, " type: " + typeof this.props.loadItems)
    var orgTotal = (this.state.page_current - 1) * this.state.pagination.pageSize;
    if (typeof this.props.loadItems ==="function") {
      var ret = this.props.loadItems(this.state.page_current, this.state.pagination.pageSize, this.state.sorter);
      //console.log("call loadItem function return: ", ret, " type: " + typeof ret, " then: " + typeof ret.then);
      if (this.isPromise(ret)) {
        ret.then(res=>{
          //console.log("call loadItem function return: ", res);

          if (res.has_more === false) {
            orgTotal += res.items.length;
            
          }

          //console.log("load item will update total to: " + orgTotal, res);
          var show_total = this.state.show_total === false && res.has_more === true ? false : true;
          this.setState({
            list_loading:false,
            list:res.items,
            show_total:show_total,
            lastIndex: res.has_more === true ? null : this.state.page_current,
            pagination:{
              ...this.state.pagination,
              total: res.has_more === true ? this.state.pagination.total : orgTotal,
              showTotal: show_total === true ? this.showTotal.bind(this) : null,
            }
          }, function(){
            if (ret.has_more === true) {
              this.hideLastPage();
            }
          })

        }).catch(err=>{
          message.error(err.message);
          this.setState({
            list_loading:false,
          })
        })
      }
      else
      {
        if (ret.has_more === false) {
          orgTotal += ret.items.length;
        }
        var show_total = this.state.show_total === false && ret.has_more === true ? false : true;
        //console.log("load item will update total to: " + orgTotal);

        this.setState({
          list_loading:false,
          list:ret.items,
          lastIndex: ret.has_more === true ? null : this.state.page_current,
          pagination:{
            ...this.state.pagination,
            total: ret.has_more === true ? this.state.pagination.total : orgTotal,
            showTotal: show_total === true ? this.showTotal.bind(this) : null,
          },

        }, function(){
          if (ret.has_more === true) {
            this.hideLastPage();
          }
        })
      }
    }
    else
    {
      this.timerTestLoadItem =setInterval(
        () => {
          this.onTimerTestLoadItem();
        },
        1000
      );
    }
  }

  onTimerTestLoadItem()
  {
    const data = [];
    var index = (this.state.page_current - 1) * this.state.pagination.pageSize;

    for (var i = 0; i < 10; i++) {
      const item={
        key: '' + (index + i),
        dept_name: '产品一部',
        git: 'git@git.ztosys.com:ztots_base/vue-xingpan.git',
        code_type: i %2 == 0 ? 'java' : 'js',
        desc: 'Sidney No. 1 Lake Park at: ' + (index + i),
        commit: '9ccea1ad',
        user:'HanJianjian at: ' + (index + i),
        app_num: i % 8 + 1,
        create_time:'2019-10-04 18:56:23',
      }

      data.push(item);
    }



    this.setState({
      list_loading:false,
      list: data,
    }, function(){
      this.hideLastPage();
    })

    this.timerTestLoadItem && clearInterval(this.timerTestLoadItem);
  }

  onSelectChange =(rowKeys)=>{
    console.log("on row select changes: ", rowKeys);
    this.setState({
      selectRows: rowKeys,
    })
  }

  onMenuClick=(menuSource, e)=>{
    var list = [];
    this.state.selectRows.map(item=>{
      list.push(this.state.list[item]);
    })

    if (list.length < 1 && this.state.menu_record != null) {
      list.push(this.state.menu_record);
    }

    var funRet = null;
    menuSource.map(item=>{
        if (typeof item.onMenuClick === "function" && item.key === e.key) {
          funRet = item.onMenuClick(list, e);
        }
    })

    if (funRet == null && typeof this.props.onMenuClick === "function") {
      funRet = this.props.onMenuClick(list, e);
    }

    if (this.isPromise(funRet)) {
      funRet.then(res=>{
        
      }).catch(err=>{
        message.error(err.message);
      })
      return;
    }

    if (this.state.selectRows.length > 0) {
      this.setState({
        menu_record: null,
        selectRows: []
      });
    }
    else
    {
      this.setState({
        menu_record: null,
      })
    }

    //this.state.menu_record
  }

  onMenuDisable=(menu, record)=>{
    if (!record) {
      return false;
    }

    if (typeof menu.disabled === "function") {
      var ret = menu.disabled(record);
      if (typeof ret === "boolean") {
        return ret;
      }
    }

    return false;
  }

  renderEmpty=()=>{
    return (
      <span></span>
    );
  }

  render() {

    const {
            menuSource,
            ...restProps
        } = this.props;

    // console.log("draw table with state: ", menuSource);

    var className = this.props.className;
    if (typeof className === "undefined") {
      className = "";
    }

    

    const rowSelection = {
      selectedRowKeys: this.state.selectRows,
      onChange: this.onSelectChange.bind(this),
      getCheckboxProps: (record)=>{
        var selectable = true;
        if (typeof this.props.selectable === "boolean") {
          selectable = this.props.selectable;
        }
        if (typeof this.props.selectable === "function") {
          selectable = this.props.selectable(record);
        }

        return {
          disabled: !selectable,
        }
      }
    };

    var listMenus = [];
    if (typeof menuSource !== "undefined" && menuSource.length > 0) {
      var subMenus = [];

      menuSource.map(menu=>{
        if (!isEmpty(menu.showable) && menu.showable === true && this.onMenuDisable(menu, this.state.menu_record) === true) {
          return;
        }

        subMenus.push (
          <Menu.Item key={menu.key} className={'menu-item'} disabled={this.onMenuDisable(menu, this.state.menu_record)}>
            {
              typeof menu.icon_style !== "undefined" ? 
              <Icon type={menu.icon} className={'ant-icon'} style={menu.icon_style}  />
              :
              <Icon type={menu.icon} className={'ant-icon'} />
            }
            <span className={'menu-text'}>{menu.title}</span>
          </Menu.Item>
        )
      })

      if (subMenus.length > 0) {
        listMenus.push(
          <Menu id={'pop-menu2'} className={'pop-menu'} onClick={this.onMenuClick.bind(this, menuSource)}>
            {subMenus}
          </Menu>
        )
      }
    }

    return (
        <div className={'lg-table-ex-wrap ' + className}
        >
        {listMenus}
        <ConfigProvider 
          renderEmpty={
            this.state.list === null ? this.renderEmpty.bind(this) : 
            typeof this.props.onEmptyRender === "function" ? this.props.onEmptyRender : this.renderEmpty.bind(this)
          }
        >
        <Table
        scroll={{x: false}}
        {...restProps}
        className={'lg-table-ex'}
        rowClassName={'lg-table-row'}
        loading={this.state.list_loading}
        pagination={this.state.pagination}
        rowSelection={this.state.showSelect === true ? rowSelection : null}

        columns={this.state.columns} dataSource={this.state.list} 

        onRow={(record, index) => {
          return {
            onClick: e => {}, // 点击行
            onDoubleClick: e => {
              if (typeof this.props.onDoubleClick === "function") {
                this.props.onDoubleClick(record);
              }
            },
            onContextMenu: e => {
              //message.success("context menu: " + JSON.stringify(record)  + " is clicked");
              //console.log("menu target: ", this.findTableEx(e.target));
              var menu = this.findTableEx(e.target);
              if (menu !== null) {
                e.preventDefault();
                this.setState({
                  menu_record: record,
                })
               
                //console.log("click pos: ("+ e.clientX + ", " + e.clientY+") ...: ", e,  " root: ", menu.root !== null ? menu.root.getBoundingClientRect() : '', menu.table.getBoundingClientRect());
                var left = e.clientX;
                if (menu.root !== null) {
                  left = e.clientX - menu.root.getBoundingClientRect().x;
                }
                var top = e.clientY;
                if (menu.root !== null) {
                  top = e.clientY - menu.root.getBoundingClientRect().y;
                }

                if (!isEmpty(menu.menu)) {
                  menu.menu.style.left=left+'px';
                  menu.menu.style.top= top+'px';
                  menu.menu.style.display='block';
                }
              }
            },
          };
        }}


        onHeaderRow={(columns, index) => {
          return {
            onClick: () => {
              //console.log("head column: ", columns, index);
              //message.success("head: "+index+"is clicked...");
            }, // 点击表头行
          };
        }}

        onChange={this.handleTablePageChange.bind(this)}

        />
        </ConfigProvider>
      </div>
    )
  }

  findTableEx=(node)=>{
    var parent = node.parentElement;
    var find=false;
    while(parent != null){
      //console.log("menu target parent.className: ", parent.className, typeof parent.className)
      if(typeof parent.className !== "string" )
      {
        parent = null;
        break;
      }

      if (parent.className.indexOf('lg-table-ex-wrap') >= 0) {
        find=true;
        break;
      }

      parent = parent.parentElement;
    }

    if (isEmpty(parent)) {
      return null;
    }

    var popMenu = null;
    var table = null;
    for (let index = 0; index < parent.childNodes.length; index++) {
      const element = parent.childNodes[index];
      if (typeof element.className === "undefined") {
        continue;
      }
      
      if (element.className.indexOf('pop-menu') >= 0) {
        popMenu = element;
      }

      if (element.className.indexOf('lg-table-ex') >= 0) {
        table = element;
      }
    }

    var defaultEndClassName = "log-dialog";
    if (typeof this.props.moduleClass === "string" && !isEmpty(this.props.moduleClass)) {
      defaultEndClassName = this.props.moduleClass;
    }

    var root = null;
    var parent2 = parent;
    while(parent2 != null){
      //console.log("menu target parent.className: ", parent2.className, typeof parent2.className)
      if(typeof parent2.className === "undefined")
      {
        parent2 = null;
        break;
      }

      if (parent2.className.indexOf(defaultEndClassName) >= 0) {
        root = parent2;
        break;
      }
      
      parent2 = parent2.parentElement;
    }

    return {root: root, parent: parent, table: table, menu: popMenu};
  }
}

export default withRouter(TableEx)
