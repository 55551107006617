import { httpPost, httpGet, baseUri } from './fetch'


/**
*
* 获取图像  state
*
**/
export const getConfirmImageApi = (data) => {
  return httpGet({
    url: baseUri('/confirm/image'),
    data
  })
}

/**
*
* 获取图像信息
{
  type:'slider',
  y:123,
  buffer:base64 encode image
  create_time:
  update_time:
}
*
**/
export const getConfirmImageInfoApi = (data) => {
  return httpGet({
    url: baseUri('/confirm/image/info'),
    data
  })
}


/**
*
* 校验图像移动位置
request:
{
  area:'+86',
  auth:'',
  state:,
  code:
  type:'slider'
}

response:
{
  id:uuid,
  auth:123,
  area:'+86'
  create_time:
  update_time:
}
*
**/
export const getConfirmImageCheckApi = (data) => {
  return httpPost({
    url: baseUri('/confirm/image/check'),
    data
  })
}



// 获取验证码
export const getConfirmCodeApi = (data) => {
  return httpPost({
    url: baseUri('/confirm/code'),
    data
  })
}
