import React, { Component} from 'react'
import { Icon, message } from 'antd'
import { Link, withRouter} from 'react-router-dom'
import { connect } from 'react-redux'
import LgHeader from '@/containers/layout/Components/header'
import LgFooter from '@/containers/layout/Components/LgFooter'
import ImgEx from '@/containers/layout/Components/ImgEx'
import './index.scss'



const mapStateToProps = (state) => {
  return {
    userInfo: state.userInfo
  }
}

const mapDispatchToProps = () => ({})

@connect(mapStateToProps, mapDispatchToProps)
class HomePage extends Component {
  constructor(props) {
    super(props);
    var addr = "http://img.logio.cn";
    var defaultAddr = "https://www.logio.cn/img";
    if (navigator.userAgent.indexOf("Chrome") > 0) {
      addr = defaultAddr;
    }

    this.state = {
      descs: [
        "需求来源于生活💖", 
        "快速迭代 跑步上线",
        "产品文档 开发设计 测试用例 一样都重要",
        "打包 部署 修复bug 测试 验收 最终是为了上线",
        "监控🔍 用户行为 异常报警🍆 弹性扩容 🌻保姆式的呵护",

        ],
      show:{index:-1, desc: "", show:true},
      topbar:false,
      slideDown:"",
      descVideoLoad: false,
      user_load: false,
      page_started: false,

      addr: addr,
      defaultAddr: defaultAddr,
      videoAddr: addr + "/logio.mp4",
    }



  }

  componentDidMount () {
    document.title = "小鹿工厂 需求 开发 管理 交付 运维 监控 弹性扩容 智能管家"

    this.timer = setInterval(
      () => {
        this.timerShowDesc();
      },
      250
    );

    let descVideo = document.getElementById("descVideo");
    descVideo.addEventListener('canplaythrough',this.onDescVideoLoaded.bind(this));
  }

  componentWillReceiveProps(nextProps){
  }

  componentWillUnmount() {
    this.timer && clearInterval(this.timer);
  }

  onUserLoad=(res)=>{
    this.setState({
      user_load: true,
      topbar:true,
    })
  }

  timerShowDesc=()=>{
    var index = this.state.show.index;
    if (index < 0) {
      index = Math.floor(Math.random()*this.state.descs.length);
    }

    if (this.state.show.show === true) {
      var showLen = this.state.show.desc.length + 1;
      var show = true;
      if (showLen > this.state.descs[index].length) {
        showLen = this.state.show.desc.length;
        show = false;
      }

      var desc = this.state.descs[index].substr(0, showLen);
      
      const changed={index:index, desc:desc, show:show}
      //console.log("run timer ....22: ", changed)

      this.setState({
        show: changed,
      }, function(){
        if (changed.show === false) {
          this.timer && clearInterval(this.timer);
            this.timer = setInterval(
              () => {
                this.timerShowDesc();
              },
              100
            );

            //console.log("will create down timer");
        }
      })

    }
    else
    {
      var showLen = this.state.show.desc.length - 1;
      var show = false;
      if (showLen <= 0) {
        showLen = 0;
        show = true;
      }

      var desc = this.state.descs[index].substr(0, showLen);
      if (show === true) {
        index = -1;
      }

      const changed={index:index, desc:desc, show:show}
      //console.log("run timer ....333: ", changed)

      this.setState({
        show:{index, desc, show},
      }, function(){
        if (changed.show === true) {
          this.timer && clearInterval(this.timer);
            this.timer = setInterval(
              () => {
                this.timerShowDesc();
              },
              250
            );

            //console.log("will create up timer");
        }
      })
    }


  }

  handleScroll=(event)=>{
      const clientHeight = document.documentElement.clientHeight; //可视区域高度
      const scrollTop  = this.dom.scrollTop;  //滚动条滚动高度
      const scrollHeight =document.documentElement.scrollHeight; //滚动内容高度
      if (this.state.user_load === true) {
        return;
      }

      var showTopbar = this.state.topbar;
      if (scrollTop >= clientHeight - 5) {
        //console.log("show top menus")
        showTopbar = true;
      }
      else
      {
        showTopbar = false;
        //console.log("hide top menus");
      }

      if (showTopbar === this.state.topbar) {
        return;
      }

      this.setState({
        topbar:showTopbar,
      })

      // console.log("scroll top: ", this.dom.scrollTop, " clientHeight: ", clientHeight, " scrollHeight: ", scrollHeight);
      if (this.state.page_started === false && this.dom.scrollTop >= clientHeight) {
        this.setState({
          page_started: true,
          slideDown: this.state.descVideoLoad === true ? "animation-slide-down" : "",
        })
      }
  }

  onNextPage=(tagId, e)=>{
    //console.log("onNextPage: ", e, tag)
    const clientHeight = document.documentElement.clientHeight; //可视区域高度
    const scrollTop  = this.dom.scrollTop;  //滚动条滚动高度
    const scrollHeight =document.documentElement.scrollHeight; 

    let anchorElement = document.getElementById(tagId);
    if(anchorElement) { 
      anchorElement.scrollIntoView({
          block: 'start',
          behavior: 'smooth' 
      }); 
    }

    if (this.state.slideDown === "") {
      this.setState({
        slideDown: "animation-slide-down",
      })
    }
  }

  timerToMoveScroll=()=>{
    const clientHeight = document.documentElement.clientHeight; //可视区域高度
    const scrollTop  = this.dom.scrollTop;  //滚动条滚动高度
    const scrollHeight =document.documentElement.scrollHeight; 
    this.dom.scrollTop=scrollTop + this.state.step;
    if (this.dom.scrollTop >= scrollHeight) {
      this.dom.scrollTop=scrollHeight;
      clearInterval(this.timerMove);
    }
  }

  onDescVideoLoaded=()=>{
    //message.success("load video finished...");
    this.setState({
      descVideoLoad:true,
      slideDown: this.state.page_started === true ? "animation-slide-down" : "",
    })
  }

  onDescVideoLoadedError=()=>{
    if (this.state.videoAddr.indexOf(this.state.defaultAddr) > 0) {
      return;
    }

    this.setState({
      videoAddr: this.state.defaultAddr + "/logio.mp4",
    })
  }


  //<img src="https://ss1.bdstatic.com/70cFvXSh_Q1YnxGkpoWK1HF6hhy/it/u=2590742802,2126459489&fm=26&gp=0.jpg"/>
            
  render() {""

    return (
      <div className={'all-page'} ref={dom => {this.dom = dom}}
        onScrollCapture={() => this.handleScroll()}
      >
        <div className={'lg-content'}>
          <LgHeader showRegister={true} home={true} show={this.state.topbar}
            onUserLoad={this.onUserLoad.bind(this)}
          />

          <div className={'one-page main-page img-bg'}>
            <div className={'middle'}>
              <div className={'hmiddle'}>
                <h2 className={'lg-title'}>logio</h2>
                <div className={'lg-desc'}>
                  /*
                  <span className={"lg-detail"}>{this.state.show.desc}</span>
                  <span className={'lg-cursor'}>|</span>
                  */
                </div>
              </div>
            </div>
            <div className={'click-down'} onClick={this.onNextPage.bind(this, 'start-page')}>
              <Icon className={'fa'} type="down" />
            </div>
          </div>

          <div id={'start-page'} className={'one-page main-page'}>
            <div className={'desc-left'}>
              <div className={'desc-content'}>
                <h3>需求 & 开发 & 运维 & 监控</h3>
                <p>
                小鹿工厂是IT从业者的乐园，让开发过程便捷、安全，AI智能帮助开发者减少繁琐的人工操作和操作失误，让开发之外的事情交给小鹿。.
                </p>
                <h4>
                <svg t="1608277961409" class="icon ant-icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="4700" width="16" height="16">
                  <path d="M229.6 345.2h62v62h-62v-62z m95.6 0h164.6v62H325.2v-62z m-95.6 184.3h62v62h-62v-62z m0 184.3h62v62h-62v-62z m540.5 135.7c71.2-1.7 127.6-60.7 126-132-1.6-68.9-57.1-124.4-126-126-71.2 1.6-127.7 60.7-126 132 1.6 68.9 57.1 124.4 126 126z m0 0" fill="#2CA6E0" p-id="4701"></path>
                  <path d="M871.6 844.3c-29.2 22.5-65.1 34.7-102.1 34.7-36.9 0-72.9-12.2-102.1-34.7-57.1 34.9-92.3 96.5-93.5 163.4h391.4c-1.2-66.9-36.6-128.6-93.7-163.4z m0 0" fill="#2CA6E0" p-id="4702"></path><path d="M108.5 885.4V132.8c0-36.7 29.4-66.3 65.5-66.3h631.3c36.2 0 65.5 29.6 65.5 66.3v458.5c25 18.3 44.7 43.1 56.8 71.6V81.1c0-41.6-33.7-75.3-75.3-75.3H127.1c-41.6 0-75.3 33.7-75.3 75.3v854.6c0 41.6 33.7 75.3 75.3 75.3h410.8c2.3-20.1 6.3-39.9 12.2-59.3h-376c-36.4-0.2-65.8-29.9-65.6-66.3z m0 0" fill="#2CA6E0" p-id="4703"></path><path d="M751.4 553.7v-24.2H325.2v62h338.5c24.9-20.8 55.4-34 87.7-37.8zM602.2 721.6c0-2.6 0.3-5.2 0.4-7.8H325.2v62h286.1c-6-17.4-9-35.7-9.1-54.2z m-13.7-334.1l24.8-24.8-32.5-32.5L556 355v12h20.5v20.5h12z m94.1-154c-2.3-2.3-4.6-2.2-7.1 0.2l-74.8 74.8c-2.4 2.4-2.5 4.8-0.2 7 2.3 2.3 4.6 2.2 7.1-0.2l74.8-74.8c2.4-2.4 2.5-4.7 0.2-7z m-3.4-30.8l61.6 61.6L597.1 408h-61.6v-61.6l143.7-143.7z m94.9 28.3l-19.7 19.7-61.5-61.7 19.7-19.7c4-4 8.8-6 14.5-6 5.7 0 10.5 2 14.5 6l32.5 32.5c4 4 6 8.8 6 14.5 0 5.8-2 10.6-6 14.7z m0 0" fill="#2CA6E0" p-id="4704"></path>
                </svg>
                  响应式需求 快速融入迭代
                </h4>
                <h4>
                  <svg t="1608277672754" class="icon ant-icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="3880" width="16" height="16">
                    <path d="M842.752 92.5696H181.248a70.93248 70.93248 0 0 0-71.2704 71.2704v552.33536a70.92224 70.92224 0 0 0 71.2704 71.2192h661.504a70.92224 70.92224 0 0 0 71.2192-71.2192V163.84A70.93248 70.93248 0 0 0 842.752 92.5696z m-7.91552 615.69024H189.11232v-536.576h645.77536zM149.54496 852.29568h724.89984v79.13472H149.54496z" p-id="3881" fill="#1296db">
                    </path>
                    <path d="M354.73408 556.53376l34.93888-35.50208-76.8-82.41152 84.0704-79.69792-34.9184-35.4816L260.4544 420.864a24.81152 24.81152 0 0 0 0 35.4816zM634.32704 521.03168l34.93888 35.50208 94.27968-100.17792a24.81152 24.81152 0 0 0 0-35.4816l-101.5808-97.4336-34.9184 35.4816 84.0704 79.69792zM578.64192 340.06016l-40.32512-16.62976-92.95872 212.04992 41.4208 16.61952 91.86304-212.03968z" p-id="3882" fill="#1296db"></path>
                  </svg>
                  开发测试 验收发布
                </h4>
                <h4>
                <svg t="1608278074439" class="icon ant-icon" viewBox="0 0 1205 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="5549" width="16" height="16">
                  <path d="M987.502008 802.955823H217.445783c-79.678715 0-144.449799-64.771084-144.449799-144.449799v-514.056225C73.253012 64.771084 138.024096 0 217.445783 0h770.056225c79.678715 0 144.449799 64.771084 144.449799 144.449799v514.313253c0 79.421687-64.771084 144.192771-144.449799 144.192771zM217.445783 87.389558c-31.35743 0-57.060241 25.445783-57.060241 57.060241v514.313253c0 31.35743 25.445783 57.060241 57.060241 57.060241h770.056225c31.35743 0 57.060241-25.445783 57.060241-57.060241V144.449799c0-31.35743-25.445783-57.060241-57.060241-57.060241H217.445783z" fill="#1296db" p-id="5550"></path>
                  <path d="M550.811245 621.751004l-161.156627-141.108434-90.473895 51.919679c-20.819277 12.080321-47.550201 4.883534-59.630522-16.192771-12.080321-20.819277-4.883534-47.550201 16.192771-59.630522l144.706827-83.277109 110.779117 97.156627 73.51004-209.73494 178.120482 173.493976 134.939759-126.200803c17.73494-16.449799 45.236948-15.678715 61.686747 2.056225 16.449799 17.73494 15.678715 45.236948-2.056225 61.686747l-195.855422 183.261044-139.823293-136.2249-70.939759 202.795181zM602.473896 1024c-24.160643 0-43.694779-19.534137-43.69478-43.694779v-221.044177c0-24.160643 19.534137-43.694779 43.69478-43.694779s43.694779 19.534137 43.694779 43.694779v221.044177c0 24.160643-19.534137 43.694779-43.694779 43.694779z" fill="#1296db" p-id="5551"></path><path d="M1161.51004 1024H43.694779c-24.160643 0-43.694779-19.534137-43.694779-43.694779s19.534137-43.694779 43.694779-43.694779h1117.815261c24.160643 0 43.694779 19.534137 43.694779 43.694779s-19.534137 43.694779-43.694779 43.694779z" fill="#1296db" p-id="5552"></path>
                </svg>
                  运行监控 弹性扩容
                </h4>
                
              </div>
            </div>
            <div className={'desc-right'}>
              {
                // https://www.typora.io//img/beta.mp4
                // https://img-logio.oss-cn-hangzhou.aliyuncs.com/logio.mp4

                this.state.descVideoLoad === true ? 
                <div className={'desc-vedio-panel ' + this.state.slideDown}>
                  <video id={'descVideo'} className={'video'} autoplay="" muted="muted" preload="preload" loop="loop" 
                    src={this.state.videoAddr}
                    onError={this.onDescVideoLoadedError.bind(this)}
                  ></video>
                </div>
                :
                <div className={'desc-vedio-panel ' + this.state.slideDown}>
                  <img src="https://www.logio.cn/img/app_show.png" />
                  <video id={'descVideo'} className={'video hide'} autoPlay="" muted="muted" preload="preload" loop="loop" 
                    src={this.state.videoAddr}
                    onError={this.onDescVideoLoadedError.bind(this)}
                  ></video>
                </div>

              }
            </div>
            <div className={'click-down'} onClick={this.onNextPage.bind(this, 'milestone-page')}>
              <Icon className={'fa'} type="down" />
            </div>
          </div>

          <div id={'milestone-page'} className={'one-page main-page'}>
            <div className={'milestone'}>
              <div className={'milestone-title'}>
                <h2>里程碑交付</h2>
                <span>在迭代的每个节点都会有交付物，并行串行相互衔接，完成整个迭代</span>
              </div>
              <ul className={'milestone-desc'}>
                <li>
                  <div className={'m-cell-img'}>
                    <ImgEx 
                      src={this.state.addr + "/demand.png"}
                      imgs={[this.state.defaultAddr + "/demand.png"]}
                    />
                  </div>
                  <div className={'m-cell-title'}>
                    需求来源
                  </div>
                  <div className={'m-cell-desc'}>
                    产研体系中的需求是整个工程管理的起点，项目开发来源于需求，服务于需求。在这里，描述您所需要的，让小鹿工厂为您打磨追踪。
                  </div>
                </li>
                <li>
                  <div className={'m-cell-img'}>
                    <ImgEx 
                      src={this.state.addr + "/sprint.png"}
                      imgs={[this.state.defaultAddr + "/sprint.png"]}
                    />
                  </div>
                  <div className={'m-cell-title'}>
                    迭代开发
                  </div>
                  <div className={'m-cell-desc'}>
                    当需求产生后，迭代即会快速创建，而作为迭代的第一个产出物，产品文档会根据需求，集成多个需求形成迭代。迭代开始开发即可介入到整个过程中来。
                  </div>
                </li>
                <li>
                  <div className={'m-cell-img'}>
                    <ImgEx 
                      src={this.state.addr + "/git.png"}
                      imgs={[this.state.defaultAddr + "/git.png"]}
                    />
                  </div>
                  <div className={'m-cell-title'}>
                    代码管理
                  </div>
                  <div className={'m-cell-desc'}>
                    小鹿工厂与Git代码管理工具共生共赢，项目辅助，我们的应用来源于代码，迭代创建分支，智能检测合并，每一步，小鹿都离不开代码。
                  </div>
                </li>
              </ul>
              <ul className={'milestone-desc'}>
                <li>
                  <div className={'m-cell-img'}>
                    <ImgEx 
                      src={this.state.addr + "/app.png"}
                      imgs={[this.state.defaultAddr + "/app.png"]}
                    />
                  </div>
                  <div className={'m-cell-title'}>
                    应用管理
                  </div>
                  <div className={'m-cell-desc'}>
                    应用产生于代码，本身将独立于代码，是代码的实践者。小鹿通过应用空间、应用主机等给应用安个家，让应用更好的服务IT从业者。
                  </div>
                </li>
                <li>
                  <div className={'m-cell-img'}>
                    <ImgEx 
                      src={this.state.addr + "/branch.png"}
                      imgs={[this.state.defaultAddr + "/branch.png"]}
                    />
                  </div>
                  <div className={'m-cell-title'}>
                  开发分支
                  </div>
                  <div className={'m-cell-desc'}>
                    这里是迭代中每个应用的具体展现的舞台，她将需求、迭代、应用、编译、部署集中管理汇聚，为开发、测试创造展现的舞台。
                  </div>
                </li>
                <li>
                  <div className={'m-cell-img'}>
                    <ImgEx 
                      src={this.state.addr + "/deploy.png"}
                      imgs={[this.state.defaultAddr + "/deploy.png"]}
                    />
                  </div>
                  <div className={'m-cell-title'}>
                    部署发布
                  </div>
                  <div className={'m-cell-desc'}>
                    迭代的最终目的即是为了发布部署，这里汇聚了所有发布的历史和发布的过程，每一个步骤都清晰明了，让发布变的更加智能。
                  </div>
                </li>
              </ul>
            </div>

          </div>
          
          <LgFooter/>
        </div>
      </div>
    )
  }
}

export default withRouter(HomePage)
