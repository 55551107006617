import React, { Component } from 'react'
import {Spin} from 'antd'
import { Link, withRouter} from 'react-router-dom'
import { connect } from 'react-redux'
import LgContentNormal from '@/containers/layout/Components/LgContentNormal'
import './index.scss'



const mapStateToProps = (state) => {
  return {
    userInfo: state.userInfo
  }
}

const mapDispatchToProps = () => ({})

@connect(mapStateToProps, mapDispatchToProps)
class LoadingPage extends Component {
  constructor(props) {
    super(props);

    this.state = {
    }
  }

  componentDidMount () {
  }

  componentWillReceiveProps(nextProps){
  }

  componentWillUnmount() {
  }


  render() {

    return (
      <Spin delay={10} size="large"
      >
      <LgContentNormal className={'list-content'} login={false} headRouter={{show:false}}>

      </LgContentNormal>
      </Spin>
    )
  }


}

export default withRouter(LoadingPage)
