/**
 * @Author: hanjianjian
 * @Date: 2019/12/22 11:16 AM
 * @desc:
 **/
import React, { Component } from 'react'
import { Layout, Avatar, Icon, Menu, Dropdown, Badge, message } from 'antd'
import { Link, withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import { MailOutlined, AppstoreOutlined, SettingOutlined } from '@ant-design/icons';
import GitImg from '@/assets/images/git.svg'
import {isEmpty} from '@/api/util'

const { SubMenu } = Menu;

const mapStateToProps = (state) => {
  return {
    userInfo: state.userInfo
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    setUserCity: dispatch.userInfo.setUserCity,
    updateUserMenu: dispatch.userInfo.updateUserMenu,
  }
}


@connect(mapStateToProps, mapDispatchToProps)
class HeadMenus extends Component {
  constructor(props) {
    super(props)
    this.state = {
      current: "",
      menus:[
      {key:'sprint', title:'需求迭代', child:[
        {key:'sprint-req-advance', title:'需求大厅', icon:'solution', to:'/demand/advance'},
        //{key:'sprint-req-dock', title:'需求对接', icon:'download', to:'/demand/dock'},
        {key:'sprint-sprint', title:'迭代', icon:'tags', to:'/sprint'},
      ]},

      {key:'app', title:'应用空间', child:[
        {key:'app-git', title:'git地址', icon:'branches', to:'/git'},
        {key:'app-app', title:'应用', icon:'appstore', to:'/app'},
        {key:'app-branch', title:'开发分支', icon:'fork', to:'/branch'},
        {key:'app-deploy', title:'部署记录', icon:'deployment-unit', to:'/deploy'},
      ]},

      {key:'setting', title:'系统配置', child:[
        {key:'setting-env', title:'环境标签', icon:'crown', to:'/sys/env'},
        {key:'setting-machine', title:'应用主机', icon:'database', to:'/machine'},
        {key:'setting-build', title:'编译主机', icon:'cloud-server', to:'/build'},
        {key:'setting-milestone', title:'里程碑', icon:'block', to:'/sys/milestone'}, 
      ]},

      {key:'user', title:'用户中心', child:[
        {key:'user-dept', title:'部门用户', icon:'team', to:'/user/dept'},
      ]},

      {key:'wiki', title:'doc', icon:'read', login: false, show: false, to:'/sys/doc',
      child:[]},

      {key:'platform', title:'公司配置', show: false, child:[
        {key:'platform-new', title:'新建公司', icon:'container', to:'/platform/new-platform', show: false},
        {key:'platform-setting', title:'公司执照', icon:'database', to:'/platform/business-license', show: false},
      ]},

      ],
    }

    var current=this.state.current;
    if (this.state.current === "") {
      this.state.menus.map(item=>{
        item.child.map(sub=>{
          if (this.props.location.pathname.startsWith(sub.to)) {
            current=sub.key;
            //console.log("will update head menus:....", this.props);
            if (this.props.onHeadMenu) {
              var menus=[];
              menus.push(item);
              menus.push({...sub});
              this.props.onHeadMenu(menus);
            }
          }
        })
      })
    }
    
  }

  componentDidMount () {
    // console.log("current user info: "+ JSON.stringify(this.props.userInfo));
  }

  handleClick = e => {
    this.setState({
      current: e.key,
    });
    //console.log("clicked menu", e.item.props.to);
    //message.success(e.key + " is clicked...");
    //this.props.history.push(e.item.props.to);

    this.props.history.push({pathname: e.item.props.to, state: {}});
  };

  onMenuTitleClick=(menu)=>{
    if (isEmpty(menu.to)) {
      return;
    }
    
    this.props.history.push({pathname: menu.to, state: {}});
  }

  render() {
    var current=this.state.current;
    var list=[];
    
    this.state.menus.map(item=>{
      var sub_list=[];
      item.child.map(sub=>{
        if (this.props.location.pathname.startsWith(sub.to)) {
          current=sub.key;
        }
        if (isEmpty(sub.show) || sub.show === true) {
          sub_list.push(
            <Menu.Item key={sub.key} to={sub.to}>
              <span className={'menu-link'} >
                <Icon type={sub.icon} className={'ant-icon'} />
                <span className={'menu-text'}>{sub.title}</span>
              </span>
            </Menu.Item>
          )
        }
      })

      if ((isEmpty(item.show) || item.show === true ) && (
        !isEmpty(this.props.userInfo.access_token) || (!isEmpty(item.login) && item.login === false)
      ) ) {
        list.push(
          <SubMenu key={item.key} title={
            isEmpty(item.icon) ?
            <span>
              {item.title}
            </span>
            :
            isEmpty(item.to) ?
            <span>
              <Icon type={item.icon}/>
              {item.title}
            </span>
            :
            <span className={"link-style-on"} onClick={this.onMenuTitleClick.bind(this, item)}>
              <Icon type={item.icon}/>
              {item.title}
            </span>
          }>
            {sub_list}
          </SubMenu>
        )
      }
    })

  return (
    <Menu className={'head-menus'} onClick={this.handleClick} selectedKeys={[current]} mode="horizontal">
      {list}
    </Menu>
    );

  }

}

export default withRouter(HeadMenus)


