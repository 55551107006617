import { message } from 'antd'
import {userAuthApi, userLogoutApi, userMeApi, getLoginApi, 
  getUserInfoApi, userMeUpdateApi, userMeAvatarApi, setUserMePlatformApi
} from '@/api/user'
import { getConfirmCodeApi} from '@/api/confirmation'
import { userPlatformsApi, createPlatformApi, updatePlatformApi, 
  createPlatformExternApi, getPlatformExternApi
} from '@/api/platform'
import {uploadFileContentApi} from '@/api/file'
import {listAuditApi} from '@/api/audit'
import {listHotCity, setCity} from '@/api/city'
import {createCompany, getCompany} from '@/api/company'
import {listDepartment} from '@/api/department'

import {isArray} from '@/api/util'


const TIMER_GET_RESULT = 60000

const userInfo = {
  
  state: {
    id: '',
    user_name: '',
    email: '',
    area: '',
    mobile: '',
    nick_name: '',
    avatar_id: '',
    avatar_url:'',
    platform_id: '',
    create_time:'',
    update_time:'',
    access_token:'',
    save_time:'',
    platforms:[],
    dept_list:[],
    city:[],
    company:{},
    menu:'',
  },
  reducers: {
    init(state){
      var userStr = window.localStorage.getItem("user");
      //console.log("type: " + (typeof userStr));
      if (typeof userStr === 'string') {
        const userInfo = JSON.parse(userStr);
        //console.log("set user info from localStorage: " + JSON.stringify(userInfo));
        return {
          ...state,
          ...userInfo
        }
      }

      return state;
    },

    resetUserInfo(state, payload){
      var platforms = state.platforms;
      var dept_list = state.dept_list;
      var ret= {
        ...payload,
        platforms: platforms,
        dept_list: dept_list,
      };

      window.localStorage.setItem("user", JSON.stringify(ret));
      return ret
    },

    setUserInfo(state, payload) {
      var strUser = window.localStorage.getItem("user");
      if (typeof strUser === 'string') {
        var objUser = JSON.parse(strUser);
        //var platformIsEmpty = !payload.platform_id;
        payload = {
          ...objUser,
          ...payload,
        }

        // if (platformIsEmpty) {
        //   payload.platform_id='';
        // }
      }

      var platforms = state.platforms;
      var dept_list = state.dept_list;

      var ret= {
        ...state,
        ...payload
      };

      window.localStorage.setItem("user", JSON.stringify(ret));
      //console.log("set user info state: ", state ," payload: ", ret);

      

      //console.log("state: " + JSON.stringify(ret));
      return {
        ...ret,
        platforms: platforms,
        dept_list: dept_list,
      };
    },

    serUserCompany(state, payload){
      window.localStorage.setItem("company", JSON.stringify(payload));
      return {
        ...state,
        company: payload,
      };
    },

    setUserMenuBase(state, payload){
      return {
        ...state,
        menu: payload,
      };
    },

    setUserPlatforms(state, payload){
      window.localStorage.setItem("platforms", JSON.stringify(payload));
      return {
        ...state,
        platforms: payload,
      };
    },

    setUserPlatformsDeparts(state, payload){
      window.localStorage.setItem("depts", JSON.stringify(payload));
      return {
        ...state,
        dept_list: payload,
      };
    },

    setUserHotCity(state, payload){
      window.localStorage.setItem("city", JSON.stringify(payload));
      return {
        ...state,
        city: payload,
      };
    },

    setUserCityFromCity(state, payload){
      var userStr = window.localStorage.getItem("city");
      var needGetFromNet=true;
      // console.log("setUserCityFromCity: " + (typeof userStr));
      if (typeof userStr === 'string') {
        const cities = JSON.parse(userStr);
        cities.id=payload.id;
        cities.city = payload.addr;

        window.localStorage.setItem("city", JSON.stringify(cities));

        return {
          ...state,
          city: cities,
        };
      }
      else
      {
        return {
          ...state
        };
      }
    },

    clearStore(state){
      window.localStorage.clear();
      return {
        id: '',
        user_name: '',
        email: '',
        area: '',
        mobile: '',
        nick_name: '',
        avatar_id: '',
        avatar_url:'',
        platform_id: '',
        create_time:'',
        update_time:'',
        access_token:'',
        save_time:'',
        platforms:[],
        dept_list:[],
      };
    },

    printState(state)
    {
      console.log("print state: " + JSON.stringify(state));
      return state;
    }
  },
  effects: {
    /**
     * @author victorzhang
     * @Description 获取验证码
     * @Date 12:00 下午 2019/12/24
     * */
    async getConfirmCodeFoo(data) {
      try {
        getConfirmCodeApi(data).then(res => {
          
        }).catch((e) => {
          if (typeof e.http_code === "undefined" || e.http_code !== 304) {
            throw e;
          }
        })
      } catch (e) {
        throw e;
      }
    },

    /**
     * @author victorzhang
     * @Description 登录
     * @Date 14:21 下午 2019/12/24
     * */
    getLoginFoo(data) {
      return new Promise((resolve, reject) => {
        userAuthApi(data).then(res => {
          var accessToken = res.access_token;
          userMeApi().then(res => {
            window.localStorage.removeItem("user")

            //var obj = JSON.parse(res);
            res.access_token = accessToken;
            res.save_time = new Date();
            const userInfo = JSON.parse(JSON.stringify(res));

            this.resetUserInfo(userInfo)
            //message.success(res)
            resolve(userInfo);

          }).catch(e=>{
            reject(e);
          })
        }).catch((e) => {
          reject(e)
        })
      })
    },

    /**
    *
    * logout
    *userLogoutApi
    ***/
    logout(){
      this.clearStore();

      return new Promise((resolve, reject) => {
        userLogoutApi({}).then(res => 
        {
            if (res.http_code === 200) 
            {
              //this.setUserInfo(res);
              // this.printState();
              window.localStorage.removeItem("user")
              resolve(res);
            } 
            else 
            {
              reject()
            }
        }).catch(() => { reject() })
      })

    },

    // get hot city list
    listHotCity(limit){
      return new Promise((resolve, reject) => {
        var userStr = window.localStorage.getItem("city");
        var needGetFromNet=true;
        //console.log("type: " + (typeof userStr));
        if (typeof userStr === 'string') {
          const cities = JSON.parse(userStr);
          if(cities.save_time)
          {            
            var current = new Date().getTime();
            var last = new Date(cities.save_time).getTime();
            if (current - last < TIMER_GET_RESULT) {
              //console.log("set hot city from localStorage: " + JSON.stringify(cities));
              needGetFromNet = false;
              this.setUserHotCity(cities);
              //this.printState();
              resolve(cities);
            }
          }
          
        }

        if (needGetFromNet)
        {
          listHotCity(limit).then(res => {
            if (res.http_code === 200) {
              res.save_time = new Date();
              this.setUserHotCity(res);
              //this.printState();
              resolve(res)
            }
            else {
              this.clearStore();
              reject()
            }
          }).catch(() => {
            reject()
          })
        }
      })
    },

    setUserCity(data){
      return new Promise((resolve, reject) => {
        setCity(data).then(res => {
          if (res.http_code === 200) 
          {
            res.save_time = new Date();
            this.setUserCityFromCity(res);
            //this.printState();
            resolve(res);
          } 
          else 
          {
            reject()
          }
        }).catch(() => { reject() })

      });
    },


    // 获取用户信息
    getUserMe(fromLocal) {
      return new Promise((resolve, reject) => {
        var userStr = window.localStorage.getItem("user");
        var needGetFromNet=true;
        if (typeof userStr === 'string') {
          const userInfo = JSON.parse(userStr);
          if (typeof fromLocal === "boolean" && fromLocal === false) {
            needGetFromNet = false;
            this.setUserInfo(userInfo);
            resolve(userInfo);

            return;
          }

          
          if(userInfo.save_time)
          {            
            var current = new Date().getTime();
            var last = new Date(userInfo.save_time).getTime();
            if (current - last < TIMER_GET_RESULT) {
              // console.log("set user info from localStorage: " + JSON.stringify(userInfo));
              needGetFromNet = false;
              this.setUserInfo(userInfo);
              //this.printState();
              resolve(userInfo);
            }
          }
          
        }

        if (typeof fromLocal === "boolean" && fromLocal === false) {
          needGetFromNet = false;
        }

        if (needGetFromNet === true)
        {
          userMeApi().then(res => {
            if (res.http_code === 200) {
              res.save_time = new Date();
              this.setUserInfo(res);
              //this.printState();
              resolve(res)
            }
            else {
              this.clearStore();
              reject({message:"服务器不在线，请稍后重试"})
            }
          }).catch((e) => {
            reject(e)
          })
        }
      })
    },

    updateUserInfo(userInfo)
    {
      return new Promise((resolve, reject) => {
        userInfo.save_time = new Date();
        this.setUserInfo(userInfo);
        resolve(userInfo);
      });

    },


    getUserCompany(data){
      return new Promise((resolve, reject) => {
          var platformsStr = window.localStorage.getItem("company");
          var needGetFromNet=true;

          if (typeof platformsStr === 'string') {
          const company = JSON.parse(platformsStr);
          if(company.save_time)
          {            
            var current = new Date().getTime();
            var last = new Date(company.save_time).getTime();
            if (current - last < TIMER_GET_RESULT) {
              //console.log("set user serUserCompany from localStorage: " + JSON.stringify(company));
              needGetFromNet = false;
              this.serUserCompany(company);
              resolve(company);
            }
          }
        }

        if (needGetFromNet)
        {
          getCompany(data).then(res => 
          {
              if (res.http_code === 200) 
              {
                //console.log("get plaform from remote: " + JSON.stringify(res));
                res.save_time= new Date();
                this.serUserCompany(res);
                resolve(res);
                //this.printState();
              } 
              else 
              {
                reject(res)
              }
          }).catch(() => { reject() })
        }

      })
    },



    // get user platforms
    getUserPlatforms(){
      let data={pos:0, limit:100}
      return new Promise((resolve, reject) => {
        var platformsStr = window.localStorage.getItem("platforms");
        var needGetFromNet=true;
        //console.log("read platforms: " + platformsStr);
        if (typeof platformsStr === 'string') {
          const platforms = JSON.parse(platformsStr);
          if(platforms.save_time)
          {            
            var current = new Date().getTime();
            var last = new Date(platforms.save_time).getTime();
            if (current - last < TIMER_GET_RESULT) {
              //console.log("set user platforms from localStorage: " + JSON.stringify(platforms));
              needGetFromNet = false;
              this.setUserPlatforms(platforms);
              //console.log("set user platforms from localStorage resolve: " + JSON.stringify(platforms));
              resolve(platforms);
            }
          }
        }

        if (needGetFromNet)
        {
          userPlatformsApi(data).then(res => 
          {
              if (res.http_code === 200) 
              {
                //console.log("get plaform from remote: " + JSON.stringify(res));
                res.save_time= new Date();
                this.setUserPlatforms(res);
                resolve(res);
                //this.printState();
              } 
              else 
              {
                reject(res)
              }
          }).catch(() => { reject() })
        }
      })
    },

    getPlatformDepartments(platform){
      return new Promise((resolve, reject) => {

        var deptStr = window.localStorage.getItem("depts");
        var needGetFromNet=true;
        //console.log("read platforms: " + platformsStr);
        if (typeof deptStr === 'string') {
          const dept_list = JSON.parse(deptStr);
          if(dept_list.save_time)
          {            
            var current = new Date().getTime();
            var last = new Date(dept_list.save_time).getTime();
            if (current - last < TIMER_GET_RESULT) {
              // console.log("set user dept_list from localStorage: " + JSON.stringify(dept_list));
              needGetFromNet = false;
              if (isArray(dept_list)) {
                dept_list.map(it=>{
                  if (it.platform_id !== platform) {
                    needGetFromNet = true;
                  }
                })
              }

              if (needGetFromNet === false) {
                this.setUserPlatformsDeparts(dept_list);
                resolve(dept_list);
              }
            }
          }
        }

        if (needGetFromNet === true)
        {
          listDepartment({pos:0, limit:100}).then(res=>
          {
              if (res.http_code === 200) 
              {
                //console.log("get plaform from remote: " + JSON.stringify(res));
                res.save_time= new Date();
                this.setUserPlatformsDeparts(res);
                resolve(res);
                //this.printState();
              } 
              else 
              {
                reject(res)
              }
          }).catch((e) => { reject(e) })
        }
      });
    },

    updateMeInfo(data){
      return new Promise((resolve, reject) => {
        userMeUpdateApi(data).then(res => 
        {
            if (res.http_code === 200) 
            {
              res.save_time = new Date();
              this.setUserInfo(res);
              // this.printState();
              resolve(res);
            } 
            else 
            {
              reject()
            }
        }).catch(() => { reject() })
      })
    },

    updateUserMenu(data){
      this.setUserMenuBase(data);
    },

    // create user avatar
    updateMeAvatar(data){
      return new Promise((resolve, reject) => {
        userMeAvatarApi(data).then(res => 
        {
            if (res.http_code === 200) 
            {
              res.save_time = new Date();
              this.setUserInfo(res);
              // this.printState();
              resolve(res);
            } 
            else 
            {
              reject()
            }
        }).catch(() => { reject() })
      })
    },

    // upload user avatar
    uploadMeAvatar(data){
      return new Promise((resolve, reject) => {
        uploadFileContentApi(data).then(res => 
        {
            if (res.http_code === 200) 
            {
              //console.log("upload user avatar response: " + JSON.stringify(res));
              //this.setUserInfo(res);
              //this.printState();
              resolve(res);
            } 
            else 
            {
              reject()
            }
        }).catch(() => { reject() })
      })
    },

    checkImage(file){
      var updateMeAvatar = this.updateMeAvatar;
      return new Promise(function(resolve, reject) {
        let filereader = new FileReader();
        filereader.onload = e => {
          let src = e.target.result;
          const image = new Image();
          image.onload = function() {
            // 获取图片的宽高，并存放到file对象中
            console.log('file width :' + this.width + ' file height :' + this.height);
            file.width = this.width;
            file.height = this.height;
            if(file.width < 200 || file.height < 200)
            {
              //console.log("upload canceled...");
              reject("选择的文件尺寸太小，请选择尺寸大于200的图片");
            }
            else
            {
              updateMeAvatar({}).then((res)=>{
                file.avatar_id=res.avatar_id;
                resolve(file);
               }).catch((err)=>{
                reject();
              });
            }
          };
          image.onerror = reject;
          image.src = src;
        };
        filereader.readAsDataURL(file);
      });
    },

    checkImagePromis({file, fun, data}){

      return new Promise(function(resolve, reject) {
        
        let filereader = new FileReader();
        filereader.onload = e => {
          let src = e.target.result;
          const image = new Image();
          image.onload = function() {
            // 获取图片的宽高，并存放到file对象中
            console.log('file width :' + this.width + ' file height :' + this.height);
            file.width = this.width;
            file.height = this.height;
            if(file.width < 200 || file.height < 200)
            {
              //console.log("upload canceled...");
              reject({message: "选择的文件尺寸太小，请选择尺寸大于200的图片"});
            }
            else
            {
              fun(data).then((res)=>{
                resolve({file: file, res: res});
               }).catch((err)=>{
                reject(err);
              });
            }
          };
          image.onerror = reject;
          image.src = src;
        };
        filereader.readAsDataURL(file);
      });
    },


    // create platform
    createPlatform(data){
      return new Promise((resolve, reject) => {
        createPlatformApi(data).then(res => 
        {
            if (res.http_code === 200) 
            {
              //console.log("upload user avatar response: " + JSON.stringify(res));
              //this.setUserInfo(res);
              //this.printState();
              window.localStorage.removeItem("platforms");
              resolve(res);
            } 
            else 
            {
              reject(res.message)
            }
        }).catch(() => { reject() })
      })
    },

    // update platform information
    updatePlatform(data){
      return new Promise((resolve, reject) => {
        updatePlatformApi(data).then(res => 
        {
            if (res.http_code === 200) 
            {
              //console.log("upload user avatar response: " + JSON.stringify(res));
              //this.setUserInfo(res);
              //this.printState();
              resolve(res);
            } 
            else 
            {
              reject(res.message)
            }
        }).catch(() => { reject() })
      })
    },

    // create platform extern information
    createPlatformExtern(data){
      return new Promise((resolve, reject) => {
        createPlatformExternApi(data).then(res => 
        {
            if (res.http_code === 200) 
            {
              //console.log("upload user avatar response: " + JSON.stringify(res));
              //this.setUserInfo(res);
              //this.printState();
              resolve(res);
            } 
            else 
            {
              reject(res.message)
            }
        }).catch(() => { reject() })
      })
    },

    // getPlatformExternApi
    getPlatformExtern(data){
      return new Promise((resolve, reject) => {
        getPlatformExternApi(data).then(res => 
        {
            if (res.http_code === 200) 
            {
              resolve(res);
            } 
            else 
            {
              reject(res.message)
            }
        }).catch(() => { reject() })
      })
    },

    listAuditResult(data){
      return new Promise((resolve, reject) => {
        listAuditApi(data).then(res => 
        {
            if (res.http_code === 200) 
            {
              resolve(res);
            } 
            else 
            {
              reject(res.message)
            }
        }).catch(() => { reject() })
      })
    },

    // end functions
  },

  useSession: false,
}

export default { userInfo }
