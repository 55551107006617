import React, { Component } from 'react'
import { 
  Layout, Button, message, Menu, Icon , Input, ConfigProvider, Popover,
  Select, Dropdown, 
} from 'antd'
import { Link, withRouter} from 'react-router-dom'
import { connect } from 'react-redux'
import './SearchEx.scss'
import { Table, Tag } from 'antd';

import {toJavaParam, toHtmlText, isEmpty, arrayGet}  from '@/api/util'
import {search} from '@/api/search'

const { Option } = Select;
const { Search } = Input;

const mapStateToProps = (state) => {
  return {
    userInfo: state.userInfo
  }
}

const mapDispatchToProps = () => ({})

@connect(mapStateToProps, mapDispatchToProps)
class SearchEx extends Component {
  constructor(props) {
    super(props);

    this.state = {
        search: this.props.value,
        search_value: undefined,
        search_url_value: undefined,
        search_list: [],
        show_drop_menu: false,
        searching: false,
        search_time: undefined,
        show_pop: false,
    }

    //console.log("search ex props: ", this.props);
  }

  componentDidMount () {
    window.onclick=this.onWindowClick.bind(this);
  }

  onWindowClick=(e)=>{
    
  }

  componentWillReceiveProps(nextProps){
    if (!isEmpty(nextProps.defaultValue)) {
      this.setState({
        search: nextProps.defaultValue,
      })
    }
  }

  componentWillUnmount() {
    
  }

  onSelectChange=(tag, value)=>{
    var data = {};
    var now = new Date();
    var isSearch = true;
    if (!isEmpty(this.state.search_time)) {
      if (now.getTime() - this.state.search_time.getTime() < 200) {
        isSearch = false;
        this.timer && clearInterval(this.timer);
        this.timer=setInterval(
          () => { this.onTimerCheckSearch(); },
          500
        );
      }
    }

    data[tag] = value.target.value;
    data[tag+"_error"] = "";
    data["searching"] = true;
    if (isSearch === true) {
      data["search_time"] = now;
    }

    if (isEmpty(data[tag])) 
    {
      data["show_drop_menu"] = false;
    }
    else
    {
      data["show_drop_menu"] = true;
    }

    if (isSearch === true) {
      data["search_value"] = data[tag];
      this.onSearch("search_list", "input", data[tag]);
    }

    if (isEmpty(data[tag])) 
    {
      data["show_pop"] = false;
    }
    else
    {
      data["show_pop"] = true;
    }

    this.setState(data)
  }

  onTimerToFocusEdit=()=>{
    if (this.input) {
      this.input.focus();

      this.timerFocus && clearInterval(this.timerFocus);
    }
  }

  onTimerCheckSearch=()=>{
    console.log("on timer to search value: " + this.state.search);

    if (this.state.search_value === this.state.search) {
      this.timer && clearInterval(this.timer);
      return;
    }

    this.onSearch("search_list", "timer", this.state.search);
    this.timer && clearInterval(this.timer);
  }

  onSearch=(tag, type, value)=>{
    if (type === "auto") {
      this.onGotoSearch(value);
      return;  
    }
    
    if (isEmpty(value)) {
      var data = {};
      data[tag] = [];
      data["searching"] = false;
      this.setState(data);
      return;
    }

    var ext = "";
    search({
      type:isEmpty(this.props.search_type) ? "app" : this.props.search_type, 
      info: value, 
      ext: ext, 
      pos: 0, 
      limit : 5
    }).then(res=>{
      var data = {};
      data[tag] = res.items;
      data["searching"] = false;
      data["show_drop_menu"] = true;
      this.setState(data);
      
    }).catch(err=>{
      message.error(err.message);
    })
  }

  onSelect=(e)=>{
    if (e.key === "loading" || e.key === "empty") {
      return;
    }

    var search = arrayGet(this.state.search_list, e.key, "src_id");
    // console.log("on search select: ", search);

    this.setState({
      show_drop_menu: false,
      search: search.name,
    })

    if (!isEmpty(this.props.onSelect) && typeof this.props.onSelect === "function") {
      this.props.onSelect(search);
    }
    
    // this.onSearch("search_list", search.name);
    this.onGotoSearch(search.name);
  }

  onSeachEntry=(e)=>{
    var search_value = e.target.value;

    if ((!isEmpty(this.props.entry_select) || this.props.entry_select === true) && this.state.search_list.length > 0) {
      var search = this.state.search_list[0];
      this.setState({
        show_drop_menu: false,
        search: search.name,
      })
      search_value = search.name;

      if (!isEmpty(this.props.onSelect) && typeof this.props.onSelect === "function") {
        this.props.onSelect(search);
      }

    }
    this.onGotoSearch(search_value);
    // console.log("on search prop: ", this.props);
  }

  onSearchBlur=(e)=>{
    if (this.state.show_drop_menu === true) {
      this.timer && clearInterval(this.timer);
      this.timer=setInterval(
        () => { this.onTimerHideDrop(); },
        200
      );
    }
  }

  onTimerHideDrop=()=>{
    this.setState({
      show_drop_menu: false,
    })

    this.timer && clearInterval(this.timer);
  }

  onSearchFocus=(e)=>{
    if (this.state.search_list.length > 1 && this.state.show_drop_menu === false) {
      this.setState({
        show_drop_menu: true,
      })
    }
  }

  onGotoSearch=(value)=>{
    // if (isEmpty(value)) {
    //   return;
    // }

    if (!isEmpty(this.state.search_url_value) && value === this.state.search_url_value) {
      return;
    }

    this.setState({
      search_url_value: value,
    })
    
    if (!isEmpty(this.props.onSearch) && typeof this.props.onSearch === "function") {
      this.props.onSearch(value);
    }

    if (!isEmpty(this.props.url_effect) || this.props.url_effect === false) {
      return;
    }

    var url = this.props.location.pathname;
    if (!isEmpty(value)) {
      url = this.props.location.pathname + "?s=" + escape(value);
    }
    
    // message.error(url + " is will be search...");
    //this.props.history.push(url, {pathname: url});
    this.props.history.replace(url, {});
  }
  
  onPopViewShow=(visible)=>{
    if (isEmpty(this.state.search)) {
      visible = false;
    }

    this.setState({
      show_pop: visible,
    })
  }

  render() {

    const {
            ...restProps
        } = this.props;

    var className = this.props.className;
    if (typeof className === "undefined") {
      className = "";
    }

    return (
        <div 
          className={'lg-search-ex-wrap ' + className}
        >
        <ConfigProvider renderEmpty={this.createEmptyList}>
        <Dropdown
          className={'lg-search-ex-drop'}
          overlay={
            this.state.searching === true ?
            <Menu onClick={this.onSelect.bind(this)}>
              <Menu.Item key="loading">
                <Icon type="loading" />
                <span className={'item-empty'}>正在搜索中...</span>
              </Menu.Item>
            </Menu>
            :
            this.state.search_list.length < 1 ?
            <Menu onClick={this.onSelect.bind(this)}>
              <Menu.Item key="empty">
                <Icon type="inbox" />
                <span className={'item-empty'}>没有搜索到数据，试试输入其他信息</span>
              </Menu.Item>
            </Menu>
            :
            <Menu onClick={this.onSelect.bind(this)}>
              {this.state.search_list.map(it=>{
                  return (
                    <Menu.Item key={it.src_id}>
                      <span>{it.name}</span>
                    </Menu.Item>
                  )
                })
              }
            </Menu>
          }
          visible={this.state.show_drop_menu}
        >
          {
            isEmpty(this.state.search_url_value) ?
            <Search 
              className={'search-input'}
              defaultValue={this.state.search}  
              value={this.state.search}
              onChange={this.onSelectChange.bind(this, "search")}
              placeholder={isEmpty(this.props.placeholder) ? "搜索你想搜索的" : this.props.placeholder}
              allowClear
              showSearch
              filterOption={false}
              ref={(input) => this.input = input}
              onSearch={this.onSearch.bind(this, "search_list", "auto")}
              onPressEnter={this.onSeachEntry.bind(this)}
              onBlur={this.onSearchBlur.bind(this)}
              onFocus={this.onSearchFocus.bind(this)}
            >
            </Search>
            :
            <Popover
            content={ <div className={'tip-show'}>{toHtmlText(this.state.search)}</div> }>
            <Search 
              className={'search-input'}
              defaultValue={this.state.search}  
              value={this.state.search}
              onChange={this.onSelectChange.bind(this, "search")}
              placeholder={isEmpty(this.props.placeholder) ? "搜索你想搜索的" : this.props.placeholder}
              allowClear
              showSearch
              filterOption={false}
              ref={(input) => this.input = input}
              onSearch={this.onSearch.bind(this, "search_list", "auto")}
              onPressEnter={this.onSeachEntry.bind(this)}
              onBlur={this.onSearchBlur.bind(this)}
              onFocus={this.onSearchFocus.bind(this)}
            >
            </Search>
            </Popover>
          }
          
          </Dropdown>
        </ConfigProvider>
      </div>
    )
  }
}

export default withRouter(SearchEx)
