import { baseUri, httpPost, httpGet, httpPut, httpDelete} from './fetch'


export const createMilestone = (data) =>{
	return httpPost({
		url: baseUri('/milestone'),
		data
	})
}

export const getMilestone = (data) =>{
	return httpGet({
		url: baseUri('/milestone'),
		data
	})
}

export const updateMilestone = (data) =>{
	return httpPut({
		url: baseUri('/milestone'),
		data
	})
}

export const deleteMilestone = (data) =>{
	return httpDelete({
		url: baseUri('/milestone'),
		params: data,
	})
}

export const listMilestone = (data) =>{
	return httpGet({
		url: baseUri('/milestone/list'),
		data
	})
}

/**
 * 
 * @param {*} data
 * id: is empty will get default list
 *  
 */
export const listMilestoneItems = (data) =>{
	return httpGet({
		url: baseUri('/milestone/item/list'),
		data
	})
}

export const createMilestoneItem = (data) =>{
	return httpPost({
		url: baseUri('/milestone/item'),
		data
	})
}

export const getMilestoneItem = (data) =>{
	return httpGet({
		url: baseUri('/milestone/item'),
		data
	})
}

export const updateMilestoneItem = (data) =>{
	return httpPut({
		url: baseUri('/milestone/item'),
		data,
	})
}

export const deleteMilestoneItem = (data) =>{
	return httpDelete({
		url: baseUri('/milestone/item'),
		params: data,
	})
}
