import { baseUri, httpPost, httpGet, httpPut, httpDelete} from './fetch'


export const createDepartment = (data) =>{
	return httpPost({
		url: baseUri('/dept'),
		data
	})
}

export const getDepartment = (data) =>{
	return httpGet({
		url: baseUri('/dept'),
		data
	})
}


export const updateDepartment = (data) =>{
	return httpPut({
		url: baseUri('/dept'),
		data
	})
}

export const deleteDepartment = (data) =>{
	return httpDelete({
		url: baseUri('/dept'),
		params: data,
	})
}

export const listDepartment = (data) =>{
	return httpGet({
		url: baseUri('/dept/list'),
		data
	})
}

export const setDepartmentUser = (data) =>{
	return httpPost({
		url: baseUri('/dept/member'),
		data
	})
}

export const updateDepartmentUser = (data) =>{
	return httpPut({
		url: baseUri('/dept/member'),
		data
	})
}

export const listDepartmentUser = (data) =>{
	return httpGet({
		url: baseUri('/dept/member/list'),
		data
	})
}