import { baseUri, httpPost, httpGet, httpPut, httpDelete} from './fetch'


export const createSprint = (data) =>{
	return httpPost({
		url: baseUri('/sprint'),
		data
	})
}

export const createHotfixSprint = (data) =>{
	return httpPost({
		url: baseUri('/sprint/hot-fix'),
		data
	})
}

export const getSprint = (data) =>{
	return httpGet({
		url: baseUri('/sprint'),
		data
	})
}

export const updateSprint = (data) =>{
	return httpPut({
		url: baseUri('/sprint'),
		data,
	})
}

export const deleteSprint = (data) =>{
	return httpDelete({
		url: baseUri('/sprint'),
		params: data,
	})
}

export const listSprint = (data) =>{
	return httpGet({
		url: baseUri('/sprint/list'),
		data
	})
}



export const listSprintDemand = (data) =>{
	return httpGet({
		url: baseUri('/sprint/req/list'),
		data
	})
}


export const updateSprintPeriod = (data) =>{
	return httpPut({
		url: baseUri('/sprint/period'),
		data,
	})
}

export const listSprintPeriod = (data) =>{
	return httpGet({
		url: baseUri('/sprint/period/list'),
		data
	})
}


export const createSprintApp = (data) =>{
	return httpPost({
		url: baseUri('/sprint/app'),
		data
	})
}

export const getSprintApp = (data) =>{
	return httpGet({
		url: baseUri('/sprint/app'),
		data
	})
}

export const deleteSprintApp = (data) =>{
	return httpDelete({
		url: baseUri('/sprint/app'),
		params: data
	})
}


export const listSprintApp = (data) =>{
	return httpGet({
		url: baseUri('/sprint/app/list'),
		data
	})
}

export const createSprintAppBranch = (data) =>{
	return httpPost({
		url: baseUri('/sprint/app/branch'),
		data
	})
}

export const getSprintAppBranch = (data) =>{
	return httpGet({
		url: baseUri('/sprint/app/branch'),
		params: data
	})
}

export const updateSprintAppBranch = (data) =>{
	return httpPut({
		url: baseUri('/sprint/app/branch'),
		params: data
	})
}


