import React from 'react'
import { ConnectedRouter } from 'connected-react-router'
import { hot } from 'react-hot-loader'
import routes from '@/routers'

const App = ({ history }) => (
    <ConnectedRouter history={history}>{routes}</ConnectedRouter>
);

export default hot(module)(App)
