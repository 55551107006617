import React from 'react';
import {withRouter} from 'react-router-dom'
import { connect } from 'react-redux'

import {toJavaParam, isArray, isEmpty, removeList, addList}  from '@/api/util'

/**
 * 图片加载失败就显示默认图片
 */
const mapStateToProps = (state) => {
    return {
      userInfo: state.userInfo
    }
  }
  
  const mapDispatchToProps = () => ({})
  
@connect(mapStateToProps, mapDispatchToProps)
class ImgEx extends React.Component {
    constructor(props) {
        super(props);

        var list = [];
        if (isArray(this.props.imgs)) {
            this.props.imgs.map(it=>{
                list.push(it);
            })
        }
        else if (!isEmpty(this.props.imgs)) {
            list.push(this.props.imgs);
        }

        this.state = { 
            imageUrl: this.props.src,
            img_list: list,
            index: 0,
        };
    }
 
    handleImageLoaded() {
     
    }
 
    handleImageErrored() {
        if (this.state.index > this.state.img_list.length) {
            return;
        }



        this.setState({ 
            imageUrl: this.state.img_list[this.state.index],
            index: this.state.index + 1,
        });
    }
 
    render() {
        return (
            <img 
                className={this.props.className}
                style={this.props.style}
                src={this.state.imageUrl}
                onLoad={this.handleImageLoaded.bind(this)}
                onError={this.handleImageErrored.bind(this)}
                alt="图片无法显示"
            />
        );
    }
}

export default withRouter(ImgEx)
