import { baseUri, httpPost, httpGet, httpPut } from './fetch'



export const createCompany = (data) =>{
	return httpPost({
		url: baseUri('/company'),
		data
	})
}

export const getCompany = (data) =>{
	return httpGet({
		url: baseUri('/company'),
		data
	})
}

export const getCompanySimple = (data) =>{
	return httpGet({
		url: baseUri('/company/simple'),
		data
	})
}


export const updateCompany = (data) =>{
	return httpPut({
		url: baseUri('/company'),
		data
	})
}
