import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'

const mapStateToProps = (state) => {
  return {
    userInfo: state.userInfo
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    getUserInfo: dispatch.userInfo.getUserInfo
  }
}

@withRouter
@connect(mapStateToProps, mapDispatchToProps)
class Index extends Component{
  constructor(props) {
    super(props)
    this.state = {}
  }

  render() {
    const { children } = this.props;

    return (
      <div className={'page-container'}>
        { children }
      </div>
    )
  }

  componentDidMount() {
    // 刷新页面时先判断是否有用户id
    // 如果id为空，调用 getUserInfo 接口去获取用户信息
    if (this.props.userInfo.union_id === '' || this.props.userInfo.union_id === null) {
      this.props.getUserInfo().then(res => {
        // 如果调用成功，说明token没过期，直接可以拿到用户信息
        // console.log(res)
      }).catch(() => {
        // 如果调用失败，说明token过期了，跳到homepage页面
        this.props.history.push('/')
      })
    }
  }
}


export default Index
