import { baseUri, httpPost, httpGet, httpPut, httpDelete} from './fetch'


/**
*
* 获取图像  state
*
**/
export const listAuditApi = (data) => {
  return httpGet({
    url: baseUri('/audit/list'),
    data
  })
}


export const listAdminAuditApi = (data) => {
  return httpGet({
    url: baseUri('/admin/audit/list'),
    data
  })
}

export const getAdminAuditApi = (data) => {
  return httpGet({
    url: baseUri('/admin/audit'),
    data
  })
}


export const updateAdminAuditApi = (data) => {
  return httpPut({
    url: baseUri('/admin/audit'),
    data
  })
}


