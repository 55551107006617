import { httpPost, httpGet, baseUri } from './fetch'


/**
*
* 获取图像 file
*
**/
export const createFileApi = (data) => {
  return httpPost({
    url: baseUri('/file'),
    data
  })
}

/**
*
* 获取图像 file
*
**/
export const getFileApi = (data) => {
  return httpGet({
    url: baseUri('/file'),
    data
  })
}

/**
*
* 获取图像 file
*
**/
export const getFileContentApi = (data) => {
  return httpGet({
    url: baseUri('/file/content'),
    data
  })
}

/**
*
* 获取图像 file
*
**/
export const uploadFileContentApi = (data) => {
  return httpPost({
    url: data.action,
    data
  })
}

export const getFileProgressApi = (data) => {
  return httpGet({
    url: baseUri('/file/progress'),
    data
  })
}