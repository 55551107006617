import { message } from 'antd'
import { Link, withRouter } from 'react-router-dom'
import axios from 'axios'

const logio = {
  version:'1.0',
  base_uri:'',
  env: "dev",
};

export function baseUri(uri)
{
  return logio.base_uri + "/" + logio.version + uri;
}



let fetch = axios.create({
  baseURL: '',
  timeout: 20000, // request timeout
  headers: {
    'Content-Type': 'application/json'
  }
})

fetch.interceptors.request.use(config => {
  return config
}, error => {
  return Promise.reject(error)
})

fetch.interceptors.response.use(response => {
  return response
}, error => {
  return Promise.reject(error)
})

const obj2Param =(data) =>{
  var _result = [];
  for (var key in data) {
    var value = data[key];
    if (value.constructor == Array) {
      value.forEach(function(_value) {
        _result.push(key + "=" + _value);
      });
    } else {
      _result.push(key + '=' + value);
    }
  }
  return _result.join('&');
}

// get 请求
export function httpGet(
  {
    url,
    params = {},
    data={}
  }) 
{
  // console.log("url: " + url + "?" + urlParam + " param: " + JSON.stringify(params));
  const paramsAll ={
    ...params,
    ...data
  }

  return new Promise((resolve, reject) => {
    fetch.get(url, {
      params:paramsAll
    }).then((res) => {
      // console.log("http get type: " + typeof res.data, res);
      if (typeof res.data === "string") {
        reject(res.data);
      }

      res.data.http_code=res.status;
      var code = 200;
      if(typeof res.data.code !=='undefined' && typeof res.data.id !=='undefined' && Object.getOwnPropertyNames(res.data).length === 4)
      {
        code = parseInt(res.data.code);
        code /= 1000;
        res.data.http_code = parseInt(code);
      }
      if (code !== 200 || logio.env === "dev") {
        console.log("http get: " + url + " response: " + JSON.stringify(res.data), " code: " + code, new Date());
      }

      if (code === 200) {
        resolve(res.data);
      }

      if (code === 401) {
        var index = window.location.href.indexOf(window.location.host);
        const path = window.location.href.substr(index + window.location.host.length);
        if (path !== "/") {
          window.location.href="/";
        }
      }

      if (code !== 200 && code !== 401) {
        reject(res.data)
      }

    }).catch(err => {
      reject(err)
    })
  })
}

// post请求
export function httpPost(
  {
    url,
    data = {},
    params = {}
  }) {
  return new Promise((resolve, reject) => {
    fetch({
      url,
      method: 'post',
      data,
      params
    }).then(res => {
      
      if (typeof res.data === "string") {
        reject(res.data);
      }

      res.data.http_code=res.status;
      var code = 200;
      if(typeof res.data.code !=='undefined' && typeof res.data.id !=='undefined' && Object.getOwnPropertyNames(res.data).length === 4)
      {
        code = parseInt(res.data.code);
        code /= 1000;
        res.data.http_code = parseInt(code);
      }

      if (code !== 200 || logio.env === "dev") {
        console.log("http post: " + url + " response: " + JSON.stringify(res.data), " code: " + code, new Date());
      }

      if (code === 200) {
        resolve(res.data)
      }

      if (code === 401) {
        var index = window.location.href.indexOf(window.location.host);
        const path = window.location.href.substr(index + window.location.host.length);
        if (path !== "/") {
          window.location.href="/";
        }
      }

      if (code !== 200 && code !== 401) {
        reject(res.data)
      }

    })
    .catch(err => {
      console.log("http post error: " + JSON.stringify(err));
      reject(err)
    })
  })
}


export function httpPut(
  {
    url,
    data = {},
    params = {}
  }) {
  return new Promise((resolve, reject) => {
    fetch({
      url,
      method: 'put',
      data,
      params
    }).then(res => {
      if (typeof res.data === "string") {
        reject(res.data);
      }

      res.data.http_code=res.status;
      var code = 200;
      if(typeof res.data.code !=='undefined' && typeof res.data.id !=='undefined' && Object.getOwnPropertyNames(res.data).length === 4)
      {
        code = parseInt(res.data.code);
        code /= 1000;
        res.data.http_code = parseInt(code);
      }

      if (code !== 200 || logio.env === "dev") {
        console.log("http put: " + url + " response: " + JSON.stringify(res.data), " code: " + code, new Date());
      }

      if (code === 200) {
        resolve(res.data)
      }

      if (code === 401) {
        var index = window.location.href.indexOf(window.location.host);
        const path = window.location.href.substr(index + window.location.host.length);
        if (path !== "/") {
          window.location.href="/";
        }
      }

      if (code !== 200 && code !== 401) {
        reject(res.data)
      }

    }).catch(err => {
      reject(err)
    })
  })
}

export function httpDelete(
  {
    url,
    data = {},
    params = {}
  }) {
  
  const paramsAll ={
    ...params,
    ...data
  }

  return new Promise((resolve, reject) => {
    fetch({
      url,
      method: 'delete',
      data,
      params
    }).then(res => {
      if (typeof res.data === "string") {
        reject(res.data);
      }

      res.data.http_code=res.status;
      var code = 200;
      if(typeof res.data.code !=='undefined' && typeof res.data.id !=='undefined' && Object.getOwnPropertyNames(res.data).length === 4)
      {
        code = parseInt(res.data.code);
        code /= 1000;
        res.data.http_code = parseInt(code);
      }

      if (code !== 200 || logio.env === "dev") {
        console.log("http delete: " + url + " response: " + JSON.stringify(res.data), " code: " + code, new Date());
      }

      if (code === 200) {
        resolve(res.data)
      }

      if (code === 401) {
        var index = window.location.href.indexOf(window.location.host);
        const path = window.location.href.substr(index + window.location.host.length);
        if (path !== "/") {
          window.location.href="/";
        }
      }

      if (code !== 200 && code !== 401) {
        reject(res.data)
      }
      
    }).catch(err => {
      reject(err)
    })
  })
}
