import React, { Component } from 'react'
import list from '../pages/sprint/list';

String.prototype.endWith=function(endStr){
	var d=this.length-endStr.length;
	return (d>=0&&this.lastIndexOf(endStr)==d);
  }

Date.prototype.format = function (fmt) { //author: meizz 
    var o = {   
		"M+" : this.getMonth()+1,                 //月份   
		"d+" : this.getDate(),                    //日   
		"h+" : this.getHours(),                   //小时   
		"m+" : this.getMinutes(),                 //分   
		"s+" : this.getSeconds(),                 //秒   
		"q+" : Math.floor((this.getMonth()+3)/3), //季度   
		"S"  : this.getMilliseconds()             //毫秒   
	  };   
	  if(/(y+)/.test(fmt))   
		{
			fmt=fmt.replace(RegExp.$1, (this.getFullYear()+"").substr(4 - RegExp.$1.length));   
		}
	  for(var k in o)   
		if(new RegExp("("+ k +")").test(fmt))   
	  fmt = fmt.replace(RegExp.$1, (RegExp.$1.length==1) ? (o[k]) : (("00"+ o[k]).substr((""+ o[k]).length)));   
	  return fmt;
}


Date.prototype.format2 = function (fmt) { //author: meizz 
    var o = {
        "M+": this.getMonth() + 1, //月份 
        "d+": this.getDate(), //日 
        "h+": this.getHours(), //小时 
        "m+": this.getMinutes(), //分 
        "s+": this.getSeconds(), //秒 
        "q+": Math.floor((this.getMonth() + 3) / 3), //季度 
		"S": this.getMilliseconds(), //毫秒
		"y+" :  this.getYear(), 
    };
    
    if (/(y+)/.test(fmt))
    {
    	//fmt = fmt.replace(RegExp.$2, (this.getFullYear() + "").substr(4 - RegExp.$2.length));
    	fmt=fmt.replace(RegExp.$1, (this.getFullYear()+"").substr(4 - RegExp.$1.length)); 
    }

    for (var k in o)
    {
    	if (new RegExp("(" + k + ")").test(fmt)) 
    	{
    		fmt = fmt.replace(RegExp.$1, (RegExp.$1.length==1) ? (o[k]) : (("00"+ o[k]).substr((""+ o[k]).length)));
    		//fmt = fmt.replace(RegExp.$2, (RegExp.$2.length == 1) ? (o[k]) : (("00" + o[k]).substr(("" + o[k]).length)));
    	}
    }

    return fmt;
}

export const formatTime=(fmt,date)=>   
{ //author: meizz   
  var o = {   
    "M+" : date.getMonth()+1,                 //月份   
    "d+" : date.getDate(),                    //日   
    "h+" : date.getHours(),                   //小时   
    "m+" : date.getMinutes(),                 //分   
    "s+" : date.getSeconds(),                 //秒   
    "q+" : Math.floor((date.getMonth()+3)/3), //季度   
    "S"  : date.getMilliseconds()             //毫秒   
  };   
  	if(/(y+)/.test(fmt))   
	{
		fmt=fmt.replace(RegExp.$1, (date.getFullYear()+"").substr(4 - RegExp.$1.length)); 
	}  

  	for(var k in o)   
    {
		if(new RegExp("("+ k +")").test(fmt))   
  		{
			fmt = fmt.replace(RegExp.$1, (RegExp.$1.length===1) ? (o[k]) : (("00"+ o[k]).substr((""+ o[k]).length)));
	  	}
	}   
  return fmt;   
}


export const later=(strTime, format, otherTime)=>{
	var time = formatGMTTime(strTime, format);
	var date = new Date(time.replace(/-/g,"/"));
	var other = null;

	if (typeof otherTime !== "undefined") {
		time = formatGMTTime(otherTime, format);
		other = new Date(time.replace(/-/g,"/"));
	}
	else
	{
		var now = new Date();
		time = formatTime(format, now);
		other = new Date(time.replace(/-/g,"/"));
	}

	//console.log("date: ", date, " other: ", other);
	
	if (date.getTime() > other.getTime()) {
		return true;
	}

	return false;
}


export const formatGMTTime=(strTime, format)=>{
	if (typeof strTime === "undefined" || strTime === null || strTime === "") {
		return strTime;
	}

	var arr = strTime.split("T");
	if (arr.length < 2) {
		return strTime;
	}
	var index = arr[1].indexOf(".");
	var time = arr[1].substring(0, index);
	var arrTime = time.split(":");

	var all = arr[0].replace(/-/g,"/") + " " + time;
	
	var date = new Date(all);
	date.setTime(date.getTime() + 8*60*60*1000);

	//console.log("current time: ", all, " as date: ", date);

	if (typeof format !== "undefined") {
		return formatTime(format, date);
	}

	var now = new Date();
	if (now.getFullYear() !== date.getFullYear()) {
		return formatTime("yyyy-MM-dd hh:00", date);
	}

	var diff = now.getTime() - date.getTime();
	if (diff < 0) {
		diff = -diff;
		if (diff < 23 * 3600 * 1000) {
			return formatTime("hh:mm:ss", date);
		}

		if (diff < 360 * 24 * 3600 * 1000) {
			return formatTime("MM-dd hh:00", date);
		}


		return formatTime("yyyy-MM-dd", date);
	}

	if (diff < 23 * 3600 * 1000) {
		return formatTime("hh:mm:ss", date);
	}

	if (diff < 360 * 24 * 3600 * 1000) {
		return formatTime("MM-dd hh:mm:ss", date);
	}

	return formatTime("MM-dd hh:mm:ss", date);
	//return date.format("yyyy-MM-dd");
}

export const gitAddr=(git)=>{
	var gitText = git;
	var gitAddr = git;
	if (git.startsWith('git@')) {
		gitAddr = gitAddr.replace(":", "/");
		gitAddr = gitAddr.replace("git@", "http://");

	}

	var index = gitText.lastIndexOf("/");
		if (index > 0) {
		gitText = gitText.substr(index + 1);
	}

	index = gitText.indexOf('.');
	if (index > 0) {
		gitText = gitText.substr(0, index);
	}

	return {url: gitAddr, name: gitText};
}


export const toJavaParam=(param)=>{
    if (typeof param !=="string") 
    {
      return param;
    }

    var arr = param.split("_");
    for (var i = 1; i < arr.length; i++) {  
      var char = arr[i].charAt(0);  
      //使用 replace()方法将数组中的每个首字母大写化  
      arr[i] = arr[i].replace(char, function replace(char) {  
          return char.toUpperCase();  
      });  
    }

    return arr.join(""); 
  }

  export const toHtmlText=(info)=>{
	var reg = new RegExp("\n", "g");
	if (isEmpty(info)) {
		info = "";
	}
	return (<p dangerouslySetInnerHTML={{ __html: info.replace(reg, "<br>")}}></p>);
  }


  export const isArray=(obj)=>{
	if(typeof obj === "object" && obj.constructor == Array)
	{
	  return true;
	}

    return false;
  }

  export const getQueryString=(query, name)=>
	{
			var reg = new RegExp("(^|\\?|&)"+ name +"=([^&]*)(\\s|&|$)", "i");
			var r = query.match(reg);
			if(r!=null)
				return unescape(r[2]);
				//return unescape(r[2]); 
			return null;
	}

export const isEmpty=(info)=>{
	if (typeof info === "undefined") {
		return true;
	}

	if (info === null) {
		return true;
	}

	if (info === "") {
		return true;
	}

	return false;
}

export const  isValidIP=(ip)=>
{
    var reg = /^(\d{1,2}|1\d\d|2[0-4]\d|25[0-5])\.(\d{1,2}|1\d\d|2[0-4]\d|25[0-5])\.(\d{1,2}|1\d\d|2[0-4]\d|25[0-5])\.(\d{1,2}|1\d\d|2[0-4]\d|25[0-5])$/
    return reg.test(ip);
}

export const  isLocalIP=(ip)=>
{
    var reg = /^(\d{1,2}|1\d\d|2[0-4]\d|25[0-5])\.(\d{1,2}|1\d\d|2[0-4]\d|25[0-5])\.(\d{1,2}|1\d\d|2[0-4]\d|25[0-5])\.(\d{1,2}|1\d\d|2[0-4]\d|25[0-5])$/
    if (!reg.test(ip)) {
		return false;
	}

	if (ip === "127.0.0.01") {
		return true;
	}

	if (ip.startsWith("192.168.")) {
		return true;
	}

	if (ip.startsWith("10.")) {
		return true;
	}

	if (ip.startsWith("172.")) {
		var arr = ip.split(".");
		var val = parseInt(arr[1]);
		if (val >= 16 && val <=31) {
			return true;
		}
	}

	return false;
}

export const removeList=(list, one, tag)=>{
	if (typeof tag === "undefined") {
		tag = "id";
	}

	var ret = [];
	list.map(it=>{
		if (it[tag] !== one[tag]) {
			ret.push(it);
		}
	})

	return ret;
}

export const addList=(list, one, tag, after)=>{
	if (typeof tag === "undefined") {
		tag = "id";
	}
	
	var find = false;
	var ret = [];
	if (isEmpty(list))
	{
		ret.push(one);
		return ret;	
	}
	
	list.map(it=>{
		if (it[tag] === one[tag]) {
			find = true;
			ret.push(one);
		}
		else
		{
			ret.push(it);
		}
	})

	if (find === true) {
		return ret;
	}

	ret = [];

	if (isEmpty(after)) {
		ret.push(one);
	}
	
	list.map(it=>{
		ret.push(it);
		
		if (after && typeof after === "string" && it[tag] === after) {
			ret.push(one);
		}
		else if (after && typeof after === "object" && it[tag] === after[tag]) {
			ret.push(one);
		}
	})

	return ret;
}

export const getList=(list, key,  tag)=>{
	var ret = null;
	if (isEmpty(tag)) {
		tag = "id";
	}

	list.map(it=>{
		if (it[tag] === key) {
			ret = it;
		}
	})

	return ret;
}

export const arrayDiff=(a1, a2)=>{
    var list = [];
    if (isEmpty(a1) && isEmpty(a2)) {
      return list;
    }

    if (isEmpty(a1)) {
      if (isArray(a2)) {
        return a2;
      }
      list.push(a2);
      return list;
    }

    if (isEmpty(a2)) {
      if (isArray(a1)) {
        return a1;
      }
      list.push(a1);
      return list;
    }

    if (!isArray(a1) && !isArray(a2)) {
      if (a1 !== a2) {
        list.push(a1);
        list.push(a2);
      }
      return list;
    }

    if (isArray(a1) && !isArray(a2)) {
      var find = false;
      a1.map(it=>{
        if (it !== a2) {
          list.push(it);
        }
        else
        {
          find = true;
        }
      })
      if (find === false) {
        list.push(a2);
      }

      return list;
    }

    if (!isArray(a1) && isArray(a2)) {
      var find = false;
      a2.map(it=>{
        if (it !== a1) {
          list.push(it);
        }
        else
        {
          find = true;
        }
      })
      if (find === false) {
        list.push(a1);
      }

      return list;
    }

    a1.map(it=>{
      var find = false;
      a2.map(it2=>{
        if (it === it2) {
          find = true;
        }
      })

      if (find === false) {
        list.push(it);
      }
    });

    a2.map(it=>{
      var find = false;
      a1.map(it2=>{
        if (it === it2) {
          find = true;
        }
      })

      if (find === false) {
        list.push(it);
      }
    });

    return list;
  }

  export const arrayGet=(arr, value, key)=>{
	if (!isArray(arr)) {
		return undefined;
	}

	if (isEmpty(key)) {
		key = "id";
	}

	var ret = undefined;
	arr.map(it=>{
		if (it[key] === value) {
			ret = it;
		}
	})

	return ret;
  }


  export const onInputChange=(tag, e)=>{
    var data = {};
    if (typeof e.target === "undefined") {
      data[tag] = e;
    }
    else
    {
      data[tag] = e.target.value;
    }
    data[tag+"_error"] = "";

    this.setState(data, function(){
      //console.log("input type: " + tag + " input value: ", data, this.state);
    })
  }


  // 函数参数必须是字符串，因为二代身份证号码是十八位，而在javascript中，十八位的数值会超出计算范围，造成不精确的结果，导致最后两位和计算的值不一致，从而该函数出现错误。
	// 详情查看javascript的数值范围
	export const checkIDCard=(idcode)=>{
	    // 加权因子
	    var weight_factor = [7,9,10,5,8,4,2,1,6,3,7,9,10,5,8,4,2];
	    // 校验码
	    var check_code = ['1', '0', 'X' , '9', '8', '7', '6', '5', '4', '3', '2'];

	    var code = idcode + "";
	    var last = idcode[17];//最后一位

	    var seventeen = code.substring(0,17);

	    // ISO 7064:1983.MOD 11-2
	    // 判断最后一位校验码是否正确
	    var arr = seventeen.split("");
	    var len = arr.length;
	    var num = 0;
	    for(var i = 0; i < len; i++){
	        num = num + arr[i] * weight_factor[i];
	    }
	    
	    // 获取余数
	    var resisue = num%11;
	    var last_no = check_code[resisue];

	    // 格式的正则
	    // 正则思路
	    /*
	    第一位不可能是0
	    第二位到第六位可以是0-9
	    第七位到第十位是年份，所以七八位为19或者20
	    十一位和十二位是月份，这两位是01-12之间的数值
	    十三位和十四位是日期，是从01-31之间的数值
	    十五，十六，十七都是数字0-9
	    十八位可能是数字0-9，也可能是X
	    */
	    var idcard_patter = /^[1-9][0-9]{5}([1][9][0-9]{2}|[2][0][0|1][0-9])([0][1-9]|[1][0|1|2])([0][1-9]|[1|2][0-9]|[3][0|1])[0-9]{3}([0-9]|[X])$/;

	    // 判断格式是否正确
	    var format = idcard_patter.test(idcode);

	    // 返回验证结果，校验码和格式同时正确才算是合法的身份证号码
	    return last === last_no && format ? true : false;
	}