import { baseUri, httpPost, httpGet, httpPut, httpDelete} from './fetch'

/**
*
* list user platforms 
*
**/
export const userPlatformsApi = (data) =>{
	return httpGet({
		url: baseUri('/platform/list'),
		data
	})
}

/**
*
* create platform
*
**/
export const createPlatformApi = (data) =>{
	return httpPost({
		url: baseUri('/platform'),
		data
	})
}

/**
*
* get platform
*
**/
export const getPlatformApi = (data) =>{
	return httpGet({
		url: baseUri('/platform'),
		data
	})
}

/**
*
* update platform
{
  id;
  startTime;
  endTime;
  traceMd5;
  status;
  admin;
  agentMd5;
  rsa;
}
*
**/
export const updatePlatformApi = (data) =>{
	return httpPut({
		url: baseUri('/platform'),
		data
	})
}

/**
*
* get platform extern
*
**/
export const getPlatformExternApi = (data) =>{
	return httpGet({
		url: baseUri('/platform/extern'),
		data
	})
}

/**
*
* create platform extern image
*
**/
export const createPlatformExternApi = (data) =>{
	return httpPost({
		url: baseUri('/platform/extern'),
		data
	})
}

/**
*
* create platform code to let someone join
{
	code;
	type: 'face' // face to face join
}
*
**/
export const createPlatformCodeApi = (data) =>{
	return httpPost({
		url: baseUri('/platform/code'),
		data
	})
}


/**
*
* get platform code information
*
**/
export const getPlatformCodeApi = (data) =>{
	return httpGet({
		url: baseUri('/platform/code'),
		data
	})
}

/**
*
* get platform code information
*
**/
export const deletePlatformCodeApi = (data) =>{
	return httpDelete({
		url: baseUri('/platform/code'),
		params:{
			...data
		}
	})
}



/**
*
* join platform 
*{
    code;
    type: 'face';
}
**/
export const userJoinPlatformApi = (data) =>{
	return httpPost({
		url: baseUri('/platform/user'),
		data
	})
}

/**
*
* join platform 
*{
    code;
    type: 'face';
}
**/
export const getJoinPlatformUserApi = (data) =>{
	return httpGet({
		url: baseUri('/platform/user'),
		data
	})
}

/**
*
* delete user from platform
*
**/
export const deleteJoinPlatformUserApi = (data) =>{
	return httpDelete({
		url: baseUri('/platform/user'),
		data
	})
}


/**
*
* list platform joined users
*
**/
export const listJoinPlatformUserApi = (data) =>{
	return httpGet({
		url: baseUri('/platform/user/list'),
		data
	})
}

