import React from 'react'
import ReactDOM from 'react-dom'
import { Provider } from "react-redux"
import { createBrowserHistory } from 'history'
import store from '@/store'
import App from './App'
import './assets/styles/index.scss'

const history = createBrowserHistory()

const render = (App) => {
  ReactDOM.render(
      <Provider store={store}>
        <App history={history} />
      </Provider>,
      document.getElementById('root'),
  )
}

render(App)
