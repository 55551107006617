import { init } from "@rematch/core"
import { createBrowserHistory } from 'history'
import { routerMiddleware, connectRouter } from 'connected-react-router'
import models from '@/models'

const history = createBrowserHistory()

const store = init({
    models,
    redux: {
      reducers: {
        router: connectRouter(createBrowserHistory()),
      },
      middlewares: [routerMiddleware(history)]
    }
})

export default store
