
import React, { Component } from 'react'
import  styles from './register.scss';
import { 
  Layout, Button, Input, Row, Col, Tooltip, InputNumber, Statistic, Typography,
  Switch, Popover, Result, 
} from 'antd'
import {Avatar, Icon, Menu, Dropdown, Badge, message, notification } from 'antd'
import { Link, withRouter} from 'react-router-dom'
import { connect } from 'react-redux'
import Circle from '@/assets/images/circle.svg'
import {createPlatformCodeApi, getPlatformCodeApi, deletePlatformCodeApi, userJoinPlatformApi} from '@/api/platform'
import {userInfoApi, getUserSimpleInfoApi} from '@/api/user'

import {isArray, formatGMTTime, isEmpty, toHtmlText} from '@/api/util'


/**
 * 子组件内容
 * @param title 标题
 * @param children 内容
 * @param restProps 传入的自定义属性
 * @returns {*}
 * @constructor 眼睛

 const LgPanel = ({children,...restProps})=>{
 */

const { SubMenu } = Menu;
const { Paragraph } = Typography;
const InputGroup = Input.Group

const mapStateToProps = (state) => {
  return {
    userInfo: state.userInfo
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    getConfirmCodeFoo: dispatch.userInfo.getConfirmCodeFoo,
    getLoginFoo: dispatch.userInfo.getLoginFoo,
  }
}

@connect(mapStateToProps, mapDispatchToProps)
class LgPlatformJoin extends Component{
  constructor(props) {
      super(props);
      this.state = {
          code_focus : true,
          code:'',
          join_panel:false,
          join_num: 0,
          join_code: '',
          list_user:[],
          list_user_pos:0,
          users: [],
          join_type: true,

          add_platform: undefined,
      };
  }

  componentDidMount () {
      this.input.focus();
  }

  componentWillReceiveProps(nextProps){
    this.setState({
      
    }, function(){
      this.input.focus();
    });

    if (nextProps.onCancel) {
      // message.error("child cancel button: " + nextProps.onCancel);
      if (this.state.join_code) {
        deletePlatformCodeApi({code: this.state.join_code}).then((res)=>{

        }).catch((err)=>{

        });
      }
      this.clear();
    }

  }

  joinOnePlatform =()=>{
    this.setState({
      code_loading:true,
      join_panel:true,
      list_user_pos:0,
      list_user:[],
    });

    if (this.state.join_type === true) {
      // add user to department
      userJoinPlatformApi({code: this.state.code, type:'face'}).then(res=>{
        this.clear();
        this.setState({
          add_platform: res,
        })
      }).catch(e=>{
        if (e.http_code === 404) {
          this.clear();
          message.error("未找到相关信息，请确认输入的是否正确或IP地址是否相同");
        }
        else
        {
          message.error(e.message);
        }
      });

      return;
    }

    createPlatformCodeApi({code: this.state.code, type:'face'}).then((res)=>{
      if (res.http_code === 200) {
        this.setState({
          join_code: this.state.code,
        })
        this.loadUserTimer = setInterval(() => this.timerLoadUser(), 5000);
      }
      else if (res.http_code === 409) {
        this.clear();
      }
      else
      {
        this.clear();
        message.error(res.message);
      }

    }).catch((e)=>{
      this.clear();
      if (e.http_code === 409) {
        message.error("当前网络下存在相同的邀请码，请换个试试");        
      }
      else {
        message.error(e.message);
      }
      
    })

  }

  clear=()=>{
    if (this.loadUserTimer) {
      clearInterval(this.loadUserTimer);
    }
    this.setState({
      code_loading:false,
      join_panel:false,
      list_user_pos:0,
      list_user:[],
      users:[],
    })
  }

  timerLoadUser=()=>{
      if (!this.state.join_code) {
        this.clear();
        return;
      }

      getPlatformCodeApi({code: this.state.join_code}).then((res)=>{
        if (res.http_code === 200) {
          if (res.users) {
            this.openOneJoinUserNotification(res.users);
          }
        }
        else{
          this.clear();
        }
      }).catch((err)=>{
        this.clear();
      })

      //this.openOneJoinUserNotification2("aid");
  }

  /* https://ss0.bdstatic.com/70cFvHSh_Q1YnxGkpoWK1HF6hhy/it/u=168533604,911552136&fm=26&gp=0.jpg
  */
  openOneJoinUserNotification=(users)=>{
    //console.log("receive users from server: " + JSON.stringify(users) + " sub list from " + this.state.users.length);
    var orgLen = this.state.list_user.length;
    this.setState({
      users:users,
      join_num: users.length,
    })

    for (var i = orgLen; i < users.length; i++) {
      var user = users[i];
      // console.log("load user info : " + uid);
      if (!isEmpty(user.id)) {
        this.setState({
          list_user_pos: this.state.list_user_pos + 1,
          list_user: this.state.list_user.push(user),
        });

        notification.open({
          message: user.nick_name + "加入了公司群",
          description: '',
          icon: 
            <Avatar className={'user-avatar'} 
              icon={<Icon type="user" />} 
              src={isEmpty(user.avatar_url) ? '' : user.avatar_url}
             />,
        });
      }
      else
      {
        getUserSimpleInfoApi({id: user}).then((res)=>{
          this.setState({
            list_user_pos: this.state.list_user_pos + 1,
            list_user: this.state.list_user.push(res),
          });
  
  
          notification.open({
            message: res.nick_name + "加入了公司群",
            description: '',
            icon: 
              <Avatar className={'user-avatar'} 
                icon={<Icon type="user" />} 
                src={isEmpty(res.avatar_url) ? '' : res.avatar_url}
               />,
          });
        })
      }
    }
    
  } 

  openOneJoinUserNotification2=(uid)=>{
    const list = [
    'https://ss0.bdstatic.com/70cFvHSh_Q1YnxGkpoWK1HF6hhy/it/u=168533604,911552136&fm=26&gp=0.jpg',
    'https://ss2.bdstatic.com/70cFvnSh_Q1YnxGkpoWK1HF6hhy/it/u=3946582080,2778150942&fm=26&gp=0.jpg',
    'https://timgsa.baidu.com/timg?image&quality=80&size=b9999_10000&sec=1579161582992&di=c38d7b454185fc1a94cee24c4dbbc769&imgtype=0&src=http%3A%2F%2Fpic1.win4000.com%2Fwallpaper%2F2018-07-20%2F5b514a7483959.jpg',
    'https://timgsa.baidu.com/timg?image&quality=80&size=b9999_10000&sec=1579161582992&di=dbc147a64959c65b3ad06d9959026d67&imgtype=0&src=http%3A%2F%2Fpic1.win4000.com%2Fwallpaper%2F2018-08-31%2F5b88ea1ee8d16.jpg',
    'https://timgsa.baidu.com/timg?image&quality=80&size=b9999_10000&sec=1579162156752&di=aab2b218dece91d0cc7084c02515d917&imgtype=0&src=http%3A%2F%2Fwww.17qq.com%2Fimg_biaoqing%2F54915156.jpeg',
    'https://ss2.bdstatic.com/70cFvnSh_Q1YnxGkpoWK1HF6hhy/it/u=1656143985,3247023819&fm=16&gp=0.jpg',
    'https://ss1.bdstatic.com/70cFuXSh_Q1YnxGkpoWK1HF6hhy/it/u=2926075004,1071024099&fm=16&gp=0.jpg',
    'https://timgsa.baidu.com/timg?image&quality=80&size=b9999_10000&sec=1579164210685&di=d4c956ce062ccd235a335d709eea14f7&imgtype=0&src=http%3A%2F%2Fimg.jk51.com%2Fimg_jk51%2F364898736.jpeg',
    ];

    var url = list[parseInt(Math.random() * list.length)];

    this.state.list_user.push({
      avatar_url: url,
      nick_name: 'aven han',
    });

    this.setState({
      list_user_pos: this.state.list_user_pos + 1,
    })

    notification.open({
        message: "aven han" + "加入了公司群",
        description: '',
        icon: 
          <Avatar
          className={'user-avatar'}
          icon={<Icon type="user" />}
          src={url}
           />
        ,
      });
  }

  onFocusCode =(e)=>{
    this.setState({
      code_focus:true,
    })
  }

  onBlurCode =(e)=>{
    this.setState({
      code_focus:false,
      code_loading:false,
    })
  }

  onInputCode =(e)=>{
    //console.log("input value: " + JSON.stringify(e.target.value));

    const strNumber = /^[0-9]*$/;
    var code = e.target.value.replace(" ","");
    if (!strNumber.test(code)) {
      return;
    }

    code = e.target.value;
    code += "";

    this.setState({
      code: code,
    }, function(){
      if (code.length > 3) {
        this.joinOnePlatform();
      };
    });

    
  }

  onShowJoin=()=>{
    this.setState({
      join_show: !this.state.join_show,
    })
  }

  formatNumber=(n)=>{
    var b=parseInt(n).toString();
     var len=b.length;
     if(len<=3){return b;}
     var r=len%3;
     return r>0?b.slice(0,r)+","+b.slice(r,len).match(/\d{3}/g).join(","):b.slice(r,len).match(/\d{3}/g).join(",");
  }

  renderAddOnePlatform=()=>{
    const platform = this.state.add_platform;

    return (
      <Result
        status="success"
        title="欢迎您加入"
        subTitle={<h4>{platform.name} {platform.department.name}</h4>}
      />
    );
  }

	render() {
        const {
            children,
            ...restProps
        } = this.props;

        if (!isEmpty(this.state.add_platform)) {
          return this.renderAddOnePlatform();
        }

        var platform ={};
        for(let index in this.props.userInfo.platforms.items) {
          let one = this.props.userInfo.platforms.items[index];
          if (one.id === this.props.userInfo.platform_id) {
            platform = one;
          }
        }

        var deptName = null;
        if (isArray(this.props.dept_list)) {
          this.props.dept_list.map(it=>{
            if (it.id === this.props.userInfo.dept_id) {
              deptName = it.name;
            }
          })
        }

        const users =[];
        var pos = 30;
        var step = 20;
        if (this.state.list_user.length < 10) {
          step = 37;
        }

        for (var i = Math.max(0, this.state.list_user_pos - 20); i < this.state.list_user.length; i++) {
          const one = this.state.list_user[i];
          users.push(
            <Tooltip title={one.nick_name} placement="bottom" >
            <Avatar
            className={'user-avatar avatar-img'}
            style={{left: pos}}
            icon={<Icon type="user" />}
            src={one.avatar_url}
            alter={one.nick_name}
             />
            </Tooltip>
          );

          pos += step;
          if (pos >= 400) {
            break;
          }
        }

        return (
	      <div className={'register-body'}>
          <div className={'panel-title'}>
            <div className={'panel-title-info'}> 
              <Switch 
                checkedChildren="加入到公司同一部门群" 
                unCheckedChildren={isEmpty(platform.name) ? "您还没公司信息，请选择加入到一个公司" : "邀请注册用户加入到【" + platform.name + "】公司群"} 
                value={this.state.join_type}
                defaultChecked 
                disabled={this.state.code_loading === true}
                onChange={(value)=>{
                  this.setState({join_type:value});
                }} 
              />
            </div>
            <div className={'panel-title-info'}>
              <div className={'pti-line'}>在同一公司网络下</div>
              <div className={'pti-line'}>
                <div className={'line-ell'}>输入相同的数字即可加入到 </div>
                {
                  isEmpty(deptName) || this.state.join_type === true ? 
                  <span>
                    同一部门
                  </span>
                   : 
                  <Popover content={<div className={'tip-show'}>{toHtmlText(platform.name)}</div> }>
                  <div className={'line-elli'} ellipsis={{ rows: 1, expandable: false }} >{deptName}</div>
                  </Popover>
                }
              </div>
            </div>
          </div>
          <div className={'panel-platform-choose'}>
            <Input className={'ppc-one small-code-input active'}
              maxLength={4}
              autoComplete="off"
              disabled={this.state.code_loading}
              placeholder={"输入4位数字口令"}
              prefix={<Icon type="number" style={{ color: 'rgba(36,150,255,0.95)',transform:'scale(1.2)' }} spin={this.state.code_loading} />}
              ref={(input) => this.input = input}
              value={ this.state.code }
              onChange={this.onInputCode}
              onFocus={this.onFocusCode}
              onBlur={this.onBlurCode}
              onPressEnter={this.joinOnePlatform.bind(this)}
                />
          </div>
          <div className={this.state.join_panel ? 'lg-join-panel show' : 'lg-join-panel hide' }>
            <div className={'lg-join-count'}>
              已有 <Icon type="usergroup-add" style={{ color: 'rgba(36,150,255,0.95)',transform:'scale(1.5)' }} />
                <span>{this.formatNumber(this.state.join_num)}</span>个新用户加入公司群
            </div>
          </div>
          <div className={'lg-join-user-avatars'}>
            {users}
          </div>
		  </div>
  		)
    }
}

export default withRouter(LgPlatformJoin);





