import React, { Component } from 'react'
import { Layout, Footer } from 'antd'
import { Link, withRouter} from 'react-router-dom'


class LgFooter extends Component {
  render() {
    return (
      <div className={'footer'}>
      	<div className={'ft-line ft-body'}>
      		<div className={'ftl-cell'}><Link to="/about/us">关于小鹿工厂</Link></div>
      		<div className={'ftl-cell'}>
			  	<div className={'ftlcl'}><Link to="/about/us#contact">商务合作</Link></div>
				<div className={'ftlcl border'}><a href="mailto:bd@logio.cn">bd@logio.cn</a></div>
			</div>
      		<div className={'ftl-cell'}>
      			<div className={'ftlcl'}>联系我们</div>
      			<div className={'ftlcl border'}><a href="mailto:vip@logio.cn">vip@logio.cn</a></div>
      		</div>
      	</div>
      	<div className={'ft-line'}>
			<a href="https://icp.chinaz.com/info?q=logio.cn" target="_blank">
				沪ICP备 18006905号-5 Copyright©2017-2021 上海骆乾信息科技有限公司版权所有
			</a>
		</div>
      	<div className={'ft-line'}>
			  亲爱的市民朋友，上海警方反诈劝阻电话“962110”系专门针对避免您财产被骗受损而设，请您一旦收到来电，立即接听。
		</div>
      </div>
    )
  }
}

//Aven@Qzer2020
// https://blog.csdn.net/nalnait/article/details/86677286?utm_medium=distribute.pc_relevant.none-task-blog-BlogCommendFromMachineLearnPai2-3.nonecase&depth_1-utm_source=distribute.pc_relevant.none-task-blog-BlogCommendFromMachineLearnPai2-3.nonecase
// Qzr@2020@db

export default LgFooter
