import React from 'react'
import {
  Route,
  Switch,
  BrowserRouter as Router
} from 'react-router-dom'

// 组件引入
import Index from '@/containers'

import loadable from "@/pages/loading/loadable";
import Home from '@/pages/home'


//import NotFound from "@/pages/404";
const NotFound = loadable(()=>import('@/pages/404'));

//import Protocol from '@/pages/about/protocol'
const Protocol = loadable(()=>import('@/pages/about/protocol'));

//import AboutUs from '@/pages/about/us'
const AboutUs = loadable(()=>import('@/pages/about/us'));

//import UserSetting from '@/pages/setting/user'
const UserSetting = loadable(()=>import('@/pages/setting/user'));

//import UserVerify from '@/pages/setting/verify';
const UserVerify = loadable(()=>import('@/pages/setting/verify'));


// platform
//import PlatformNewPage from "@/pages/platform/new";
const PlatformNewPage = loadable(()=>import('@/pages/platform/new'));

//import BusinessLicensePage from "@/pages/platform/license";
const BusinessLicensePage = loadable(()=>import('@/pages/platform/license'));


// app
//import GitPage from "@/pages/git";
const GitPage = loadable(()=>import('@/pages/git'));

//import AppPage from "@/pages/app";
const AppPage = loadable(()=>import('@/pages/app'));

//import DeployListPage from "@/pages/deploy/list";
const DeployListPage = loadable(()=>import('@/pages/deploy/list'));

//import DeployDetailPage from "@/pages/deploy/detail";
const DeployDetailPage = loadable(()=>import('@/pages/deploy/detail'));

//import BranchListPage from "@/pages/branch/list";
const BranchListPage = loadable(()=>import('@/pages/branch/list'));

//import DemandCreatePage from "@/pages/demand/create";
const DemandCreatePage = loadable(()=>import('@/pages/demand/create'));

//import DemandDetailPage from "@/pages/demand/detail";
const DemandDetailPage = loadable(()=>import('@/pages/demand/detail'));

//import DemandPage from "@/pages/demand/list";
const DemandPage = loadable(()=>import('@/pages/demand/list'));


//import SprintPage from "@/pages/sprint/list";
const SprintPage = loadable(()=>import('@/pages/sprint/list'));

//import SprintCreatePage from "@/pages/sprint/create";
const SprintCreatePage = loadable(()=>import('@/pages/sprint/create'));

//import SprintDetailPage from "@/pages/sprint/detail";
const SprintDetailPage = loadable(()=>import('@/pages/sprint/detail'));

//import MachinePage from "@/pages/machine"
const MachinePage = loadable(()=>import('@/pages/machine'));

//import BuildPage from "@/pages/build"
const BuildPage = loadable(()=>import('@/pages/build'));

//import EnvPage from "@/pages/system/env"
const EnvPage = loadable(()=>import('@/pages/system/env'));

//import MilestonePage from "@/pages/system/milestone/list"
const MilestonePage = loadable(()=>import('@/pages/system/milestone/list'));

//import MilestoneDetailPage from "@/pages/system/milestone/detail"
const MilestoneDetailPage = loadable(()=>import('@/pages/system/milestone/detail'));

//import DocDefaultPage from "@/pages/system/doc/default"
const DocDefaultPage = loadable(()=>import('@/pages/system/doc/default'));

//import DocEnvPage from "@/pages/system/doc/env"
const DocEnvPage = loadable(()=>import('@/pages/system/doc/env'));


//import MessageListPage from "@/pages/message/list";
const MessageListPage = loadable(()=>import('@/pages/message/list'));

//import DeptUserListPage from "@/pages/user/dept-user";
const DeptUserListPage = loadable(()=>import('@/pages/user/dept-user'));

//import AuditListPage from "@/pages/audit";
const AuditListPage = loadable(()=>import('@/pages/audit'));




const routers = (
    <Router>
        <Index>
            <Switch>
              <Route path="/404" component={NotFound}/>
              <Route path="/about/protocol" component={Protocol} />
              <Route path="/about/us" component={AboutUs} />

              <Route path="/setting/user" component={UserSetting} />
              <Route path="/setting/verify" component={UserVerify} />

              <Route path="/platform/new" component={PlatformNewPage} />
              <Route path="/platform/new/:id" component={PlatformNewPage} />
              <Route path="/platform/license/:id" component={BusinessLicensePage} />
              
              //demand
              <Route path="/demand/advance/create" component={DemandCreatePage} />
              <Route path="/demand/advance/detail/:id" component={DemandDetailPage} />
              <Route path="/demand/advance" component={DemandPage} />
              
              <Route path="/sprint/detail/:id" component={SprintDetailPage} />
              <Route path="/sprint/create/:ids" component={SprintCreatePage} />
              <Route path="/sprint" component={SprintPage} />

              
              // app
              <Route path="/git" component={GitPage} />

              <Route path="/app" component={AppPage} />
              <Route path="/branch" component={BranchListPage} />
              <Route path="/deploy/detail/:id" component={DeployDetailPage} />
              <Route path="/deploy" component={DeployListPage} />
              

              // setting
              <Route path="/machine" component={MachinePage} />
              <Route path="/build" component={BuildPage} />
              <Route path="/sys/env" component={EnvPage} />
              <Route path="/sys/milestone/detail/:id" component={MilestoneDetailPage} />
              <Route path="/sys/milestone" component={MilestonePage} />
              
              
              <Route path="/sys/doc/env" component={DocEnvPage} />
              <Route path="/sys/doc" component={DocDefaultPage} />
              
              
              <Route path="/message/list" component={MessageListPage} />
              <Route path="/message/:id" component={MessageListPage} />

              <Route path="/user/dept" component={DeptUserListPage} />

              <Route path="/audit/list" component={AuditListPage} />

              <Route path="/" component={Home} />
            </Switch>
        </Index>
    </Router>
)

export default routers
