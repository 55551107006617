/**
 **/
import React, { Component } from 'react'
import { Layout, Avatar, Icon, Menu, Dropdown, Badge, Tooltip, Modal, message } from 'antd'
import { Link, withRouter} from 'react-router-dom'
import { connect } from 'react-redux'
import PlatformMenus from './PlatformMenus';
import UserMenus from './UserMenus';
import Logo from '@/assets/images/logo_1.svg'
import './layout.scss'

import LgPanel from '@/containers/layout/Components/panel'
import LgRegister from '@/containers/layout/Components/register'
import LgPlatformCreate from '@/containers/layout/Components/PlatformCreate'
import LgPlatformJoin from '@/containers/layout/Components/PlatformJoin'
import HeadMenus from '@/containers/layout/Components/HeadMenus'

import {isArray, isEmpty}  from '@/api/util'


const mapStateToProps = (state) => {
  return {
    userInfo: state.userInfo,
    
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    getUserMe: dispatch.userInfo.getUserMe,
    getUserPlatforms : dispatch.userInfo.getUserPlatforms,
    listHotCity: dispatch.userInfo.listHotCity,
  }
}



@connect(mapStateToProps, mapDispatchToProps)
class LgHeader extends Component {
  constructor(props) {
    super(props)

    // console.log("header props: ", props);

    this.state = {
      header_width: document.body.offsetWidth - 20,
      register:true,
      register_show:false,
      login_show:false,
      platform_show:false,
      join_panel_show:false,
      join_btn_cancel:false,

      // dialog
      show_join_dialog:false,

      show_new_dialog: false,
      dialog_save_new_loading: false,

      dept_list: [],
    }

    if (typeof this.props.login !=="undefined" && this.props.login === false) {
      

      return;
    }

    // console.log("user props: ", this.props)

    this.props.getUserMe().then((res)=>{
      //this.props.history.push("/");
      //message.error("get user success");
      // this.props.onPlatformCreate();

      if (this.props.onUserLoad) {
        this.props.onUserLoad(res);
      }

      //console.log("get user after user info: " + JSON.stringify(this.props.userInfo));
      if (this.props.userInfo.access_token && !this.props.userInfo.platform_id && this.props.onPlatformCreate) {
        //this.props.onPlatformCreate();
      }
    }).catch(err=>{
      if (typeof err.message !== "undefined") {
        message.error(err.message);
      }
    });

  }

  componentDidMount () {
    var list = document.getElementsByClassName('all-page');
    if (list.length > 0) {
      var first = list[0];
      //console.log("all width: " +first.clientWidth+ " get scroll size: ", this.getScrollTop())
      this.setState({
        header_width: first.clientWidth - 9,
      })
    }
    
  }

  /*
   * @Author: RenLe
   * @Date: 2020-09-15 11:11:59
   * @LastEditors: RenLe
   * @LastEditTime: 2020-09-15 11:12:00
   * @Description: file content
   * @FilePath: /vue-truckrouter/Users/renle/Desktop/获取滚动条.js
   */
  getScrollTop=()=>{
      var scroll_top = 0;
      if (document.documentElement && document.documentElement.scrollTop) {
          scroll_top = document.documentElement.scrollTop;
      }
      else if (document.body) {
          scroll_top = document.body.scrollTop;
      }
      return scroll_top;
  }

  componentWillReceiveProps(nextProps){
    var platform_show=false;
    if (this.props.userInfo.access_token && 
      (typeof this.props.userInfo.platform_id === 'undefined' || this.props.userInfo.platform_id === '') &&
      this.props.home && this.props.home===true) 
    {
      platform_show = true;
    }

    this.setState({
      platform_show: platform_show,
    })
  }

  onSubMenu=(data)=>{
    // message.error("create platform menu..." + JSON.stringify(data));
    if (data.menu === "platform" && data.sub_menu === 'new') {
      this.setState({
        show_new_dialog:true,
      })
    }

    if (data.menu === "user" && data.sub_menu === 'user-join') {
      this.setState({
        show_join_dialog:true,
      });
    }
  }

  onPanelCancel=(type, cancelBtn)=>{
    // message.error("type: " + type + " is clicked..." + " cancel: " + cancelBtn);
    if (type === "platform_create") {
      this.setState({
        platform_show:false,
      })
    }
    else if (type === "join_panel") {
      this.setState({
        show_join_dialog:true,
      })
    }
    else if (type === "register_panel") {
      this.setState({
        register_show: false,
      })
    }
    else if (type === "login_panel") {
      this.setState({
        login_show: false,
      })
    }

  }

  onRegisterClick=(type)=>{

    if (this.props.onRegister) {
      this.props.onRegister(type);
    }

    if (type === "login") {
      this.setState({
        login_show:true,
      })
    }
    else if (type === "register") {
      this.setState({
        register_show:true,
      })
    }
  }

  onRegisterSuccess=(type)=>{
      this.setState({
        register_show:false,
      })
  }

  onLoginSuccess=(type)=>{
      this.setState({
        login_show:false,
      })
  }

  onHeadMenu=(menus)=>{
    if (this.props.onHeadMenu) {
      this.props.onHeadMenu(menus);
    }
  }

  onLoadDepartment=(rspd)=>{
    this.setState({
      dept_list: rspd.items,
    })

    if (typeof this.props.onLoadDepartment === "function") {
      this.props.onLoadDepartment(rspd);
    }
  }

  render() {
    const {
            children,
            showRegister,
            show,
            ...restProps
        } = this.props;

    // console.log("current user info: " + JSON.stringify(this.props.userInfo));
    var className = "logio-header lg-fix ";
    if (this.props.userInfo.access_token) {
      className = "logio-header lg-fix "
    }

    if (!show && show === false) {
      className += " hide";
    }

    //console.log("current topbar class name: " + className + " this props: ", this.props);

    return (
      <div ref="header" 
        className={className}
        style={{width:this.state.header_width}}
      >
              {
                this.props.userInfo.access_token ? '' :
                showRegister === true ?
                <LgPanel show={this.state.login_show} onCancel={this.onPanelCancel.bind(this, "login_panel")} cancel={false}>
                  <LgRegister register={false} onSuccess={this.onLoginSuccess} />
                </LgPanel>
                : ''
              }

              {
                this.props.userInfo.access_token ? '' :
                showRegister === true ?
                <LgPanel show={this.state.register_show} onCancel={this.onPanelCancel.bind(this, "register_panel")} cancel={false}>
                  <LgRegister register={true} onSuccess={this.onRegisterSuccess} />
                </LgPanel>
                : ''
              }

              {this.props.userInfo.access_token ? this.renderNewAndJoinDialog() : ''}
              {this.props.userInfo.access_token ? this.renderJoinDialog() : ''}


          <div className={'header-content'}>
            <div className={'hc-body'}>           
            <div className={'header-content-logo'}>
              <Link to="/">
                <img src={Logo} alt=""/>
                <span>小鹿工厂</span>
              </Link>
            </div>
            <div className={'head-menus-left'}>
              <HeadMenus onHeadMenu={this.onHeadMenu.bind(this)} />
            </div>
            
            
            <div className={'head-menus-right'}>

              {
                this.props.userInfo.access_token ? '' : 
                showRegister === true ? 
                <div className={'header-link'}>
                  <div onClick={this.onRegisterClick.bind(this, "login")} to="#">
                      <span>登录小鹿工厂</span>
                  </div>
                </div>
                : ''
              }

              {
                this.props.userInfo.access_token ? '' : 
                showRegister === true ? 
                <div className={'header-link'}>
                  <div onClick={this.onRegisterClick.bind(this, "register")} to="#">
                      <span className={'pub-btn'}>免费注册</span>
                  </div>
                </div>
                : ''
              }

              {
                !isEmpty(this.props.userInfo.access_token) ? 
                <PlatformMenus 
                  subMenu={this.onSubMenu.bind(this)} 
                  login={this.props.login}
                  onLoadDepartment={this.onLoadDepartment.bind(this)}
                /> : ''
              }

              {
                this.props.userInfo.access_token ? <UserMenus subMenu={this.onSubMenu.bind(this)}/> :''
              }

            </div>
            </div>

          </div>
        </div>
    )
  }

  renderJoinDialog=()=>{
    var platform ={};
    for(let index in this.props.userInfo.platforms.items) {
      let one = this.props.userInfo.platforms.items[index];
      if (one.id === this.props.userInfo.platform_id) {
        platform = one;
      }
    }

    return (
      <Modal
        //title={isEmpty(platform.name) ? "邀请注册用户加入部门" : "邀请注册用户加入【" + platform.name + "】的部门"}
        title={"邀请/加入到公司同一部门群"}
        className={'log-dialog'}
        centered
        closable={false}
        visible={this.state.show_join_dialog}
        confirmLoading={this.state.dialog_save_join_loading}
        onCancel={()=>{
          this.setState({show_join_dialog: false});
        }}
        destroyOnClose={true}
        footer={null}
        width={500}
        cancelText="取消"
        okText="保存"
      >
          {
            <LgPlatformJoin 
              onCancel={this.state.join_btn_cancel} 
              dept_list={this.state.dept_list}
            />
          }
      </Modal>
    )
  }

  renderNewAndJoinDialog=()=>{
    return (
      <Modal
        title={"邀请注册用户加入公司群"}
        className={'log-dialog'}
        centered
        closable={false}
        visible={this.state.show_new_dialog}
        confirmLoading={this.state.dialog_save_new_loading}
        onCancel={()=>{
          this.setState({show_new_dialog: false});
        }}
        destroyOnClose={true}
        footer={null}
        width={500}
        cancelText="取消"
        okText="保存"
      >
          {
            <LgPlatformCreate />
          }
      </Modal>
    )
  }

}

export default withRouter(LgHeader)
