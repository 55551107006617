import React, { Component } from 'react'
import { message, Icon , Popover,
  Progress, Modal, Select
} from 'antd'
import { Link, withRouter} from 'react-router-dom'
import { connect } from 'react-redux'
import LgContentNormal from '@/containers/layout/Components/LgContentNormal'
import './index.scss'

import TableEx from '@/containers/layout/Components/TableEx'
import SearchEx from '@/containers/layout/Components/SearchEx';
import {search} from '@/api/search'

import {listSprint, deleteSprint, createHotfixSprint} from '@/api/sprint'
import {listMilestone, listMilestoneItems} from '@/api/milestone'
import {formatGMTTime, toHtmlText, isEmpty, getQueryString} from '@/api/util'

const { Option } = Select;

const mapStateToProps = (state) => {
  return {
    userInfo: state.userInfo
  }
}

const mapDispatchToProps = () => ({})

@connect(mapStateToProps, mapDispatchToProps)
class SpringPage extends Component {
  constructor(props) {
    super(props);

    var search = getQueryString(this.props.location.search, "s");
    this.state = {
      search: search,
      search_reload: false,
      updatedRecord:null,
      columns: this.getColumns(),
      menuSource: this.getMenus(),

      sprint: undefined,
      milestone_list: [],
      show_hotfix_dialog: false,
      dialog_save_hotfix_loading: false,
      milestone: undefined,

      detail: "",
      detail_max: 500,
    }
  }

  componentDidMount () {
  }

  componentWillReceiveProps(nextProps){
  }

  componentWillUnmount() {
    
  }

  getColumns=()=>{
    const columns = [
      {
        title: '迭代名',
        align:'left',
        width:'370px',
        dataIndex: 'title',
        key: 'title',
        render:(text, record, index)=>{
          var list=[];

          var className = "gray";
          var tipName = "常规版本";
          if (record.type === "normal") {
            className = "blue";
            tipName = "常规版本";
          }
          else if (record.type === "urgent") {
            className = "yellow";
            tipName = "紧急版本";
          }
          else if (record.type === "urgent-fix") {
            className = "red";
            tipName = "紧急修复";
          }
          else if (record.type === "hot-fix") {
            className = "red";
            tipName = "紧急修复";
          }

          list.push(
            <Popover content={ <div className={'tip-show'}>{toHtmlText(tipName)}</div> }>
            <svg xmlns="http://www.w3.org/2000/svg" version="1.1" className={'dot'}>
              <circle class={"loading-circle " + className} cx="50%" cy="50%" r="25%"/>
            </svg> 
            </Popover>
          );

          list.push(<span className={'editnot'}>{text}</span>);
          
          // list.push(
          //   <div className={'middle-text'}>
          //     <div><span className={'editnot'}>{text}</span></div>
          //   </div>
          // );

          return (
            <span className={'editnot'}>
              {list}
            </span>
          )
        }
      },
      {
        title: '分支',
        dataIndex: 'branch',
        key: 'branch',
        align:'center',
        extText:(text, record, index)=>{
          if (typeof text === "undefined") {
            return "-";
          }
          return text;
        }
      },
      {
        title: '负责人',
        dataIndex: 'manager_name',
        key: 'manager_name',
        align:'center',
      },
      
      {
        title: '版本进度',
        dataIndex: 'percent',
        key: 'percent',
        align:'center',
        render:(text, record, index)=>{
          var percent = text/100;
          var percentInfo = "当前进度： " + percent + "%\n";
          if (typeof record.period_name !== "undefined") {
            percentInfo += "里程节点：" + record.period_name;
          }

          return(
            <Popover content={ <div className={'tip-show'}>{toHtmlText(percentInfo)}</div> }>
            <Progress
              strokeColor={{
                '0%': 'rgb(106, 181, 106)',
                '100%': 'rgb(2,131, 11)',
              }}
              status={percent === 100 ? "success": "active"}
              showInfo={percent === 100 ? true:false}
              percent={percent}
            />
            </Popover>
          )
        }
      },
      {
        title: '需求/应用',
        dataIndex: 'req_num',
        key: 'req_num',
        align:'center',
        extText:(text, record, index)=>{
          return text +"/" + record.app_num;
        }
      },
      {
        title: '创建时间',
        key: 'create_time',
        dataIndex: 'create_time',
        edit:false,
        extText:(text, record, index) =>{
          return formatGMTTime(text);
        },
      },
      {
        title: '更新时间',
        key: 'update_time',
        dataIndex: 'update_time',
        edit:false,
        extText:(text, record, index) =>{
          return formatGMTTime(text);
        },
      },

    ];

    return columns;
  }

  getMenus=()=>{
    const menuSource=[
      {
        key:'edit', 
        icon:'edit', 
        title:'编辑',
        onMenuClick:(records, e)=>{
          if (records.length > 0 && records[0] && records[0].id) {
            this.props.history.push({pathname: "/sprint/detail/" + records[0].id, query:{edit: true}});
          }
        },
      },

      {
        key:'detail', 
        icon:'info-circle', 
        title:'详细信息',
        onMenuClick:(records, e)=>{
          if (records.length > 0 && records[0] && records[0].id) {
            this.props.history.push({pathname: "/sprint/detail/" + records[0].id, query:{id: ""}});
          }
        },
      },
      {
        key:'sprint', 
        icon:'diff', 
        title:'需求文档', 
        disabled:(record)=>{
          return false;
        },
        onMenuClick:(records, e)=>{
          if (records.length > 0 && records[0] && records[0].id) {
            this.props.history.push({pathname: "/sprint/detail/" + records[0].id, query:{edit: true}});
          }
        },
      }, 
      {
        key:'hot-fix', 
        icon:'warning', 
        icon_style: { color: 'rgba(206, 59, 22,0.95)' },
        title:'紧急修复 hot-fix', 
        disabled:(record)=>{
          if (record.type === "urgent-fix" || record.type === "hot-fix") {
            return  true;
          }

          return false;
        },
        onMenuClick:(records, e)=>{
          if (this.state.milestone_list.length < 1) {
            listMilestone({pos: 0, limit: 0}).then(res=>{
              this.setState({
                milestone_list: res.items,
              })
            }).catch(e=>{
              message.error(e.message);
            })
          }

          this.setState({
            show_hotfix_dialog: true,
            sprint: records[0],
            dialog_save_hotfix_loading: false,
            detail: '',
          })
        },
      }, 
      {
        key:'delete', 
        icon:'rest', 
        title:'关闭迭代', 
        show:(record)=>{
          if (record.user_id === this.props.userInfo.id) {
            return true;
          }
          return false;
        },
        disabled:(record)=>{
          return false;
        },
        onMenuClick:(records, e)=>{
          deleteSprint({id: records[0].id}).then(res=>{
            this.setState({
              updatedRecord:{
                ...res,
                is_deleted: true,
              }
            }, ()=>{
              this.setState({updatedRecord: null})
            })
          }).catch(e=>{
            message.error(e.message);
          })
        },
      }, 
    ];

    return menuSource;
  }
  

  loadItems=(page, size, sort)=>{
    var pos = (page - 1) * size;

    if (isEmpty(this.state.search)) {
      return listSprint({pos: pos, limit: size});
    }

    return new Promise((resolve, reject) => {
      search({type: 'sprint', info: this.state.search, pos: pos, limit: size}).then(res=>{
        var list = [];
        res.items.map(it=>{
          list.push(it.ext);
        })
        res.items=list;

        resolve(res);
      }).catch(e=>{
        message.error(e.message);
      })

    });
  }

  saveEdit=(record, dataIndex, newValue)=>{
    if (!newValue || newValue === "") {
      return;
    }

    var data = {};
    if (dataIndex === "git_desc") {
      data["desc"] = newValue;
    }
    else
    {
      data[dataIndex] = newValue;
    }
    data["id"] = record.id;

    //console.log("update record: ", data);
    //message.success("id: " + record.id + " will save " + dataIndex + " with new value: " + newValue);
    //return updateGit(data);
  }

  renderEmpty=()=>{
    return (
      <div className={"empty-info-large link-style-on"}
      onClick={(e)=>{
        this.props.history.push({pathname: "/demand/advance"});
     }}
    >
      <Icon type="plus" className={"ant-icon"} />
      <span>点击进入需求大厅，选择需要迭代的需求~~</span>
    </div>
    )
  }

  render() {

    return (
      <LgContentNormal className={'list-content'} login={true} headRouter={{show:true}}>
          <div className={'list-head'}>
            <div className={'head-search'}>
              <SearchEx 
                placeholder="输入 迭代名 版本号" 
                search_type={"sprint"}
                onSearch={(value)=>{
                  this.setState({
                    search: value,
                    search_reload: true,
                  }, ()=>{
                    this.setState({
                      app_reload: false,
                    })
                  })
                }}
              />
            </div>
          </div>
          <div className={'list-body'}>
            <TableEx 
            showSelect={false}
            scroll={{x: false}}
            menuSource={this.state.menuSource}
            columns={this.state.columns}
            reload={this.state.search_reload}
            loadItems={this.loadItems.bind(this)}
            saveEdit={this.saveEdit.bind(this)}
            updatedRecord={this.state.updatedRecord}
            onEmptyRender={this.renderEmpty.bind(this)}
            onDoubleClick={(record)=>this.props.history.push({pathname: "/sprint/detail/" + record.id})}
            />
        </div>
        {isEmpty(this.state.sprint) ? '' : this.renderHotfixDialog()}
      </LgContentNormal>
    )
  }

  onSelectChange=(tag, value)=>{
    var data = {};
    data[tag] = value;
    data[tag+"_error"] = "";
    this.setState(data);
  }

  onSaveHotfixSprint=()=>{
    if (this.state.dialog_save_hotfix_loading === true) {
      return;
    }

    if (isEmpty(this.state.milestone)) {
      this.setState({
        milestone_error:'请选择hot-fix的修复里程碑',
      })
      return;
    }

    const data = {
      id: this.state.sprint.id,
      type: 'hot-fix',
      milestone: this.state.milestone,
      reason: this.state.detail,
    }

    this.setState({
      dialog_save_hotfix_loading: true,
    })

    createHotfixSprint(data).then(res=>{
      this.setState({
        updatedRecord: res,
        dialog_save_hotfix_loading: false,
        show_hotfix_dialog: false,
      }, ()=>{
        this.setState({
          updatedRecord: null,
        })
      })
    }).catch(e=>{
      this.setState({
        dialog_save_hotfix_loading: false,
      })
      message.error(e.message);
    })
  }

  onInputChange=(tag, e)=>{
    var data = {};
    data[tag] = e.target.value;
    data[tag+"_error"] = "";

    this.setState(data)
  }

  renderHotfixDialog=()=>{
    return (
      <Modal
        title={this.state.sprint.title + "的紧急修复 hot-fix"}
        className={'log-dialog'}
        centered
        closable={false}
        visible={this.state.show_hotfix_dialog}
        confirmLoading={this.state.dialog_save_hotfix_loading}
        onCancel={()=>{
          this.setState({show_hotfix_dialog: false});
        }}
        onOk={this.onSaveHotfixSprint.bind(this)}
        destroyOnClose={true}
        width={700}
        cancelText="取消"
        okText="创建紧急修复版本"
      >
          {
            <div className={'lgd-content'}>
            <table className={'lgd-post-content'}>
              <tbody>
                <tr>
                  <th className={'pc-title min-none'}><span className={'must'}>*</span>里程碑</th>
                  <th>
                    <Select 
                      className={'ant-len'}
                      defaultValue={this.state.milestone}  
                      value={this.state.milestone}
                      onChange={this.onSelectChange.bind(this, "milestone")}
                      placeholder={"请选择hot-fix的里程碑"}
                      allowClear
                    >
                      {this.state.milestone_list.map(it=>{
                        return (
                          <Option value={it.id}>{it.name}</Option>
                        )
                      })
                      }
                    </Select>
                    <div className={this.state.milestone_error ? "ptl-error" : "ptl-error none"}>
                      <Icon type="info-circle" />
                      <span>{this.state.milestone_error}</span>
                    </div>
                    <div className={this.state.milestone_ok ? "ptl-ok" : 'ptl-ok none'}>
                      <Icon type="check" />
                    </div> 
                  </th>
                </tr>
                <tr>
                    <th className={'pc-title left'}>紧急原因</th>
                    <th>
                      <textarea 
                        className={'pc-detail left not-margin'} 
                        rows={6} autocomplete={'off'} 
                        placeholder="请输入紧急修复的原因"
                        value={this.state.detail}
                        onChange={this.onInputChange.bind(this, "detail")}
                      />
                      <div className={"ptl-ok"}>
                      </div>
                      <div className={this.state.detail_error ? "ptl-error" : "ptl-error none"}>
                        <Icon type="info-circle" />
                        <span>{this.state.detail_error}</span>
                      </div>
                      <div className={this.state.detail.length < this.state.detail_max ? "ptl-len" : 'ptl-len red'}>
                        <span>剩余{this.state.detail_max - this.state.detail.length}字</span>
                      </div>
                    </th>
                  </tr>

              </tbody>
            </table>
          </div>
          }
      </Modal>
    )
  }


}

export default withRouter(SpringPage)
