
import React, { Component } from 'react'
import { Layout, Icon, Tooltip } from 'antd'
import  styles from './index.scss';

/**
 * 子组件内容
 * @param title 标题
 * @param children 内容
 * @param restProps 传入的自定义属性
 * @returns {*}
 * @constructor 眼睛

 const LgPanel = ({children,...restProps})=>{
 */

export default class LgPanel extends Component{

	constructor(props) {
        super(props);
        this.state = {
            show: props.show === false ? false : true,
        };
    }

   getX(obj){  

		var parObj=obj;    
		var left=obj.offsetLeft;    
		while(parObj=parObj.offsetParent){    
			left+=parObj.offsetLeft;    
		}    

		return left;    
	}

	getY(obj){    

		var parObj=obj;    
		var top=obj.offsetTop;    
		while(parObj = parObj.offsetParent){    
			top+=parObj.offsetTop;    
		}    
		return top;    
	}

    onDismiss =(e)=>{
    	var parent = e.target.parentElement;
    	var find=false;
    	while(parent != null){
    		if(typeof parent.className === "object")
    		{
    			find=true;
    			break;
    		}

    		//if (parent != null) { console.log("current mouse (" + e.clientX + ", " + e.clientY + ") ==> class name: " + parent.className); }

    		if (parent.className.indexOf('panel-main') >= 0) {
    			find=true;
    			break;
    		}

    		parent = parent.parentElement;
    		
    	}

    	if (!find) {
    		this.setState({
	            show: false,
	          });

            if (this.props.onCancel) {
                this.props.onCancel(false);  
            }
    	}
    }

    onClickCancelButton=()=>{
        this.setState({
            show:false,
        });

        if (this.props.onCancel) {
            this.props.onCancel(true);  
        }
    }

    componentWillReceiveProps(nextProps){
    	this.setState({
            show: nextProps.show === false ? false : true,
          }, function(){
            
          });
    }

	render() {
        const {
            children,
            className,
            show,
            cancel,
            ...restProps
        } = this.props;

        var  closeBtn = false;
        if (typeof cancel === "undefined" || cancel === true) {
            closeBtn = true;
        }

        // console.log("panel props: " + JSON.stringify(this.props.cancel) + " cancel: " + closeBtn)

        return (
	      <div className={this.state.show ? 'panel-main ' + className : 'panel-main display ' + className} onClick={this.onDismiss}>
            
			<div className={'panel-body'} {...restProps}>
                {
                    closeBtn ? 
                    <div className="lg-panel-close" onClick={this.onClickCancelButton}>
                        <Tooltip title="关闭按钮">
                        <Icon type="close" style={{ color: 'rgba(0,0,0,0.55)',transform:'scale(1.5)' }}  />
                        </Tooltip>
                    </div>
                    : ''
                }
                
				{children}
			</div>
		  </div>
  		)
    }
}

