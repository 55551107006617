import React, { Component } from 'react'
import { Layout, Button, message, Menu, Icon , Input, Breadcrumb} from 'antd'
import { Link, withRouter} from 'react-router-dom'
import { connect } from 'react-redux'
import LgHeader from '@/containers/layout/Components/header'
import LgFooter from '@/containers/layout/Components/LgFooter'
import './index.scss'


const mapStateToProps = (state) => {
  return {
    userInfo: state.userInfo
  }
}

const mapDispatchToProps = () => ({})



@connect(mapStateToProps, mapDispatchToProps)
class LgContentNormal extends Component {
	constructor(props) 
	{
		super(props)
	    this.state = {
			menus: [{icon:'home', to:"/"}],
			selectMenus:null,
	    }
	}

	componentDidMount () {
		
	}

	handleScroll=(event)=>{
	}

	onHeadMenu=(menus)=>{
		this.setState({
			menus:[
				... this.state.menus,
				... menus,
			],
		})
	}

	onBreadClick=(item, e)=>{
		if (typeof item.to !== "undefined") {
			this.props.history.push({pathname: item.to});
		}
	}

	onBreadSubMenusClicked = e => {
		this.setState({
			selectMenus: e.key,
		});

		this.props.history.push(e.item.props.to);
	};

	checkSelectMenu=(item)=>{
		if (this.props.location.pathname.startsWith(item.to)) {
			return item.key;
		}

		if (!item.child) {
			return null;
		}

		var key = null;
		item.child.map(sub=>{
			var ret = this.checkSelectMenu(sub);
			if (ret != null) {
				key = ret;
			}
		})

		return key;
	}

	createBreadMenus=(item)=>{
		if (typeof item.child === "undefined") {
			return null;
		}

		var current = this.state.selectMenus;
		if (current == null) {
			current = this.checkSelectMenu(item);
		}

		return (
			<Menu onClick={this.onBreadSubMenusClicked} selectedKeys={[current]} >
				{
					item.child.map(sub=>{
						return (
							<Menu.Item key={sub.key} to={sub.to}>
							  <span className={'menu-link'} >
								<Icon type={sub.icon} className={'ant-icon'} />
								<span className={'menu-text'}>{sub.title}</span>
							  </span>
							</Menu.Item>
						  );
					})
				}
			</Menu>
		)
	}


	render() 
	{
		const {
            children,
            className,
            login,
			onUserLoad,
			onLoadDepartment,
            ...restProps
		} = this.props;
		
		var menus = this.state.menus;
		var headRouter = this.props.headRouter;
		if (typeof headRouter === "undefined") {
			headRouter = {show:false};
		}

		if (typeof headRouter.menus !== "undefined") {
			menus = menus.concat(headRouter.menus);
		}

		if (menus.length > 1) {
			menus[menus.length - 1].to="";
		}

    	return (
    		<div className={'all-page'} ref={dom => {this.dom = dom}}
		        onScrollCapture={() => this.handleScroll()}
		      >
		        <div className={'lg-content'} {...restProps}>
		        	<LgHeader onRegister={this.onRegister} onLogin={this.onLogin} 
		        		login={login && true} onUserLoad={onUserLoad}
						onHeadMenu={this.onHeadMenu.bind(this)}
						onLoadDepartment={onLoadDepartment}
		        	/>
					<div className={'one-page lg-list-content'}  >
				        	
						<div className={'lg-list-body ' + className}>
						<div className={'lg-page-leave'}>{
							headRouter.show === true ?
							<Breadcrumb>
								{
									menus.map(item=>{
										if (item.child) {
											return (
												<Breadcrumb.Item
													className={typeof item.to !== "undefined" && item.to !=="" ? 'link-style-on bread' : 'point bread'}
													onClick={this.onBreadClick.bind(this, item)} 
													overlay={()=>{return this.createBreadMenus(item)}}
												>
													{
														item.icon ? <Icon type={item.icon} className={'ant-icon'} /> : ''
													}
													{
														item.title ? <span>{item.title}</span> : ''
													}
												</Breadcrumb.Item>
											)
										}
										else {
											return (
												<Breadcrumb.Item
													className={typeof item.to !== "undefined" && item.to !=="" ? 'link-style-on bread' : 'point bread'}
													onClick={this.onBreadClick.bind(this, item)} 
												>
													{
														item.icon ? <Icon type={item.icon} className={'ant-icon'} /> : ''
													}
													{
														item.title ? <span>{item.title}</span> : ''
													}
												</Breadcrumb.Item>
											)
										}
									})
								}
							</Breadcrumb>
							: ''
						} 
						</div>
						{children}
						</div>
					</div>
		          <LgFooter/>
		        </div>
		    </div>
    	);
	}

}

export default withRouter(LgContentNormal);
