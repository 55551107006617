import { baseUri, httpPost, httpGet, httpPut } from './fetch'



export const listHotCity = (data) =>{
	return httpGet({
		url: baseUri('/city/hot/list'),
		data
	})
}

export const listCity = (data) =>{
	return httpGet({
		url: baseUri('/city/list'),
		data
	})
}

export const setCity = (data) =>{
	return httpPost({
		url: baseUri('/city?id=' + data),
	})
}

export const listSpellCity = (data) =>{
	return httpGet({
		url: baseUri('/city/spell/list'),
		data
	})
}


export const listCategory = (data) =>{
	return httpGet({
		url: baseUri('/category/list'),
		data
	})
}

export const listAllCategory = (data) =>{
	return httpGet({
		url: baseUri('/category/all/list'),
		data
	})
}

export const listHotCategory = (data) =>{
	return httpGet({
		url: baseUri('/category/hot/list'),
		data
	})
}


export const listWelfares = (data) =>{
	return httpGet({
		url: baseUri('/welfare/list'),
		data
	})
}